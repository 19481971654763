<template>
  <!-- 버튼, 현위치 이동경로 -->
  <div v-if="isPositionAndLienShowButton" class="line-show" style="z-index:2; position: absolute" :style="[{'top' : getTop + 'px'}, isLeftPosition == true? {'left' : getPositionAndLienShowLineValue + 'px'} : {'right' : getPositionAndLienShowLineValue + '%'}]">
    <div class="line-show-select" :class="{ 'map-active': !isShowLine }" @click="onClickShowLine(false)">{{ $t("btn-map-in-tab-current-loc") }}</div>
    <div class="line-show-select" :class="{ 'map-active': isShowLine }" @click="onClickShowLine(true)">{{ $t("btn-map-in-moving-route") }}</div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";
export default {
  name: "MapPositionAndLine",
  components: {  },
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,
      isShowLine : false,
    };
  },
  mounted() {
  },
  created() {
  },
  computed : {
    ...mapState({
      isPositionAndLienShowButton : state => state.map.isPositionAndLienShowButton,
      isPositionAndLienUnder : state => state.map.isPositionAndLienUnder,
      isLngLatShowUnder : state => state.map.isLngLatShowUnder,
      
      positionAndLienShowLineValue : state => state.map.positionAndLienShowLineValue,
    }),
    getTop(){
      let positionAndLineTop = this.$store.getters["map/getPositionAndLineTop"];
      let lngLatShowUnder = this.getIsLngLatShowUnder ? 53 : 0;
      let positionAndLienUnder = this.getIsLngLatShowUnder ? 53 : 0;
      return positionAndLineTop + lngLatShowUnder + positionAndLienUnder - 10;
    },
    getIsLngLatShowUnder(){
      return this.isLngLatShowUnder;
    },
    getIsPositionAndLienUnder(){
      return this.isPositionAndLienUnder;
    },
    getPositionAndLienShowLineValue(){
      if(this.isRightAddVideoPosition) {
        return this.positionAndLienShowLineValue + this.defaultLineValue;
      }
      return this.positionAndLienShowLineValue;
    },
  },
  watch: {
    isPositionAndLienShowButton(value){
      value;
    },
  },
  methods: {
    onClickShowLine(isLine){
      this.isShowLine = isLine;
      this.$emit("map-position-line-is-line", isLine)
    },
    showChangeModal(isShow) {
      if(isShow === false) {
        this.isTypeChangeModal = false;
        return;
      }
      this.isTypeChangeModal = !this.isTypeChangeModal;
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
