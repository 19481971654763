const MapImageName = Object.freeze({
  ID_ICON_DRONE: "ID_ICON_DRONE",
  ID_ICON_DRONE_S: "ID_ICON_DRONE_S",
  ID_ICON_USER: "ID_ICON_USER",
  ID_ICON_CONTROLLER: "ID_ICON_CONTROLLER",
  ID_ICON_STREAMER : "ID_ICON_STREAMER",
  ID_ICON_OTHER_STREAMER : "IC_ICON_STREAMER",
  ID_ICON_OTHER_DRONE: "ID_ICON_OTHER_DRONE",
  ID_ICON_OTHER_DRONE_S: "ID_ICON_OTHER_DRONE_S",
  ID_ICON_OTHER_USER: "ID_ICON_OTHER_USER",
  ID_ICON_OTHER_USER_1: "ID_ICON_OTHER_USER_1",
  ID_ICON_OTHER_USER_2: "ID_ICON_OTHER_USER_2",
  ID_ICON_OTHER_USER_3: "ID_ICON_OTHER_USER_3",
  ID_ICON_OTHER_CONTROLLER: "ID_ICON_OTHER_CONTROLLER",
  ID_ICON_RED_CIRCLE: "ID_ICON_RED_CIRCLE",
  ID_ICON_POINT_CAUTION: "ID_ICON_POINT_CAUTION",
  ID_ICON_POINT_DANGER: "ID_ICON_POINT_DANGER",
  ID_ICON_POINT_HOME: "ID_ICON_POINT_HOME",
  ID_ICON_POINT_SEARCH: "ID_ICON_POINT_SEARCH",
  ID_ICON_POINT_TARGET: "ID_ICON_POINT_TARGET",
  ID_ICON_POINT_STATION: "ID_ICON_POINT_STATION",
  ID_ICON_POINT_CAUTION_SELECTED: "ID_ICON_POINT_CAUTION_SELECTED",
  ID_ICON_POINT_DANGER_SELECTED: "ID_ICON_POINT_DANGER_SELECTED",
  ID_ICON_POINT_HOME_SELECTED: "ID_ICON_POINT_HOME_SELECTED",
  ID_ICON_POINT_SEARCH_SELECTED: "ID_ICON_POINT_SEARCH_SELECTED",
  ID_ICON_POINT_TARGET_SELECTED: "ID_ICON_POINT_TARGET_SELECTED",
  ID_ICON_POINT_STATION_SELECTED: "ID_ICON_POINT_STATION_SELECTED",
  ID_ICON_POINT_MAPPING : "ID_ICON_POINT_MAPPING",

  ID_ICON_WAYPOINT: "ID_ICON_WAYPOINT",
  ID_ICON_WAYPOINT_SELECTED: "ID_ICON_WAYPOINT_SELECTED",

  ID_ICON_STATION: "ID_ICON_STATION",

  ID_ICON_SITE: "ID_ICON_SITE",
  ID_ICON_SITE_CIRCLE: "ID_ICON_SITE_CIRCLE",
  ID_ICON_SITE_SELECTED: "ID_ICON_SITE_SELECTED",

  ID_ICON_DRONE_RED : "ID_ICON_DRONE_RED",
  ID_ICON_DRONE_BLUE : "ID_ICON_DRONE_BLUE",
  ID_ICON_DRONE_ORANGE : "ID_ICON_DRONE_ORANGE",
  ID_ICON_DRONE_PURPLE : "ID_ICON_DRONE_PURPLE",

  ID_SCHEDULE_STATION_GREY: "ID_SCHEDULE_STATION_GREY",
  ID_SCHEDULE_STATION_GREEN: "ID_SCHEDULE_STATION_GREEN",
});

export default MapImageName;
