<template>
  <div v-if="isFollowShowButton" style="z-index:2; position: absolute" :style="[{'top' : getFollowButtonTop + lngLatShowUnder + positionAndLienUnder + 'px' }, isLeftPosition == true? {'left' : defaultLineValue + 'px'} : {'right' : defaultLineValue + '%'}]">
    <div class="small" style="display: contents;" @click="isFollow">
      <img v-if="!isFollowShow" src="@/assets/images/map/ic_map_follow_up.svg" class="button-icon" />
      <img v-if="isFollowShow" src="@/assets/images/map/ic_map_follow_up_selected.svg" class="button-icon" />
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";
export default {
  name: "MapFollow",
  components: {  },
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,
      //버튼 전체 값 region
      isFollowShow : true,
    };
  },
  mounted() {
    this.$emit("map-is-follow",this.isFollowShow);
  },
  created() {
  },
  computed : {
    ...mapState({
      isFollowShowButton : state => state.map.isFollowShowButton,
    }),
    getFollowButtonTop(){
      return this.$store.getters["map/getFollowButtonTop"];
    },
  },
  watch: {
  },
  methods: {
    isFollow() {
      this.isFollowShow = !this.isFollowShow;
      this.$emit("map-is-follow",this.isFollowShow);
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
