<template>
  <!-- 버튼, 타입 변경 -->
  <div v-show="isTypeChangeShowButton" class="map-btn1" style="z-index:2; position: absolute" :style="[{'top' : getButtonTop + lngLatShowUnder + positionAndLienUnder + 'px' }, isLeftPosition == true? {'left' : defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'}]">
    <div class="small" style="display: contents" @click="showModal">
      <img v-if="isTypeChangeModal" src="@/assets/images/map/ic_map_type_selected.svg" class="button-icon"/>
      <img v-if="!isTypeChangeModal" src="@/assets/images/map/ic_map_type.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";

export default {
  name: "MapTypeChange",
  components: {  },
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,
      isTypeChangeModal : false,
      typeSelectName: "normal",
    };
  },
  mounted() {
  },
  created() {
  },
  computed : {
    ...mapState({
      isTypeChangeShowButton : state => state.map.isTypeChangeShowButton,
    }),
    getButtonTop() {
      return this.$store.getters["map/getTypeChangeButtonTop"];
    },
  },
  watch: {
  },
  methods: {
    getLngLatShowUnder(){
      return this.lngLatShowUnder;
    },
    onTypeSelectItemName(name){
      this.typeSelectName = name;
      this.$emit("map-type-select-item-name", this.typeSelectName)
    },
    showModal() {
      if(this.isTypeChangeModal) {
        this.hideModal();
        return;
      }
      this.isTypeChangeModal = true;
      this.$emit("map-type-show-modal",this.isTypeChangeModal);
    },
    hideModal() {
      this.isTypeChangeModal = false;
      this.$emit("map-type-hide-modal",this.isTypeChangeModal);
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
