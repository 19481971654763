<template>
  <div v-if="isNoFlightAreaShowButton" class="map-btn9" style="z-index:2; position: absolute" :style="[{'top' : getNoFlightAreaButtonTop + lngLatShowUnder + positionAndLienUnder + 'px' }, isLeftPosition == true? {'left' : defaultLineValue + 'px'} : {'right' : defaultLineValue + '%'}]">
    <div class="small" style="display: contents" @click="noFlightAreaShowModal">
      <img v-if="isNoFlightAreaShow" src="@/assets/images/map/ic_map_noflight_area_select.svg" class="button-icon"/>
      <img v-if="!isNoFlightAreaShow" src="@/assets/images/map/ic_map_noflight_area.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";
export default {
  name: "MapTypeChange",
  components: {  },
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,
      isNoFlightAreaShow : false,
    };
  },
  mounted() {
  },
  created() {

  },
  computed : {
    ...mapState({
      isNoFlightAreaShowButton : state => state.map.isNoFlightAreaShowButton,
    }),
    getNoFlightAreaButtonTop(){
      return this.$store.getters["map/getNoFlightAreaButtonTop"];
    },
  },
  watch: {
  },
  methods: {
    noFlightAreaShowModal() {
      if(this.isNoFlightAreaShow) {
        this.noFlightAreaHideModal();
        return;
      }
      this.isNoFlightAreaShow = true;
      this.$emit("map-no-flight-area-show-modal",this.isNoFlightAreaShow);
    },
    noFlightAreaHideModal() {
      this.isNoFlightAreaShow = false;
      this.$emit("map-no-flight-area-hide-modal", this.isNoFlightAreaShow);
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
