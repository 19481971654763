const MapType = Object.freeze({
  ID_NORMAL: {
    name: "normal",
    mapboxUrl: "mapbox://styles/mapbox/streets-v11",
    kakaoTypeId: "ROADMAP",
  },
  ID_TERRAIN: {
    name: "terrain",
    mapboxUrl: "mapbox://styles/mapbox/outdoors-v11",
    kakaoTypeId: "SKYVIEW",
  },
  ID_SATELLITE: {
    name: "satellite",
    mapboxUrl: "mapbox://styles/mapbox/satellite-v9",
    kakaoTypeId: "HYBRID",
  },
});

export default MapType;
