class MapUtils{
   EARTH_RADIUS = 6371000 //지구 m 단위의 거리 
    constructor(){
        this.point = null
        this.bearing = null
        this.distance = null
    }
    /**
     * 메게변수는 .lat, .lng형식의 객체로 넘겨줘야함.
     * @param {Object} point1 
     * @param {Object} point2 
     * @returns 두 점의 m단위의 거리를 반환한다.
     */
    calculateDistance(point1, point2){
        const lat1Rad = (point1.lat * Math.PI) / 180;
        const lon1Rad = (point1.lng * Math.PI) / 180;
        const lat2Rad = (point2.lat * Math.PI) / 180;
        const lon2Rad = (point2.lng * Math.PI) / 180;

        const distance = this.EARTH_RADIUS * Math.sqrt(Math.pow(lat2Rad - lat1Rad, 2) + Math.pow(lon2Rad - lon1Rad, 2));
        return distance
    }
   
    /**
     * 
     * @param {Object} point1 
     * @param {Object} point2 
     * @param {Object} point3 
     * @returns 수선의 발 위도 경도를 반환함
     * point1과 point2는 연결된 선 point3에서 선쪽으로 수선의발을 그린다. 
     */
    findFootOfPerpendicular(point1, point2, point3) {
        const lat1 = point1.lat;
        const lon1 = point1.lng;
        const lat2 = point2.lat;
        const lon2 = point2.lng;
        const lat3 = point3.lat;
        const lon3 = point3.lng;
    
        // 선분 AB의 방향 벡터
        const vectorAB = { x: lon2 - lon1, y: lat2 - lat1 };
    
        // 점 P에서 선분 AB까지의 벡터
        const vectorAP = { x: lon3 - lon1, y: lat3 - lat1 };
    
        // 내적 계산 함수
        const dotProduct = (v1, v2) => v1.x * v2.x + v1.y * v2.y;
    
        // 수선의 발 계산
        const scalar = dotProduct(vectorAP, vectorAB) / dotProduct(vectorAB, vectorAB);
        const footOfPerpendicular = {
            lat: lat1 + scalar * (lat2 - lat1),
            lng: lon1 + scalar * (lon2 - lon1)
        };
    
        return footOfPerpendicular;
    }
    /**
     * 
     * @param {Object} point1 
     * @param {Object} point2 
     * @returns 
     */
    calculateBearing(point1,point2) {
        // 위도와 경도를 라디안으로 변환
        const lat1Rad = this.toRadians(point1.lat);
        const lon1Rad = this.toRadians(point1.lng);
        const lat2Rad = this.toRadians(point2.lat);
        const lon2Rad = this.toRadians(point2.lng);
    
        // 두 지점 간의 경도 차이
        const deltaLon = lon2Rad - lon1Rad;
    
        // 방위각 계산
        const y = Math.sin(deltaLon) * Math.cos(lat2Rad);
        const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) - Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLon);
        const bearing = Math.atan2(y, x);
    
        // 라디안 값을 도로 변환하고 0 ~ 360 범위로 정규화
        const bearingDegrees = (bearing < 0) ? (bearing + 2 * Math.PI) : bearing;
        return (bearingDegrees * (180 / Math.PI));
    }
   /**
    * 
    * @param {Object} point 
    * @param {Number} bearing 
    * @param {Number} distance 
    * @param {Number} index 
    * @returns index:index, lat: newLatitude, lng: newLongitude
    */
    calculateNewPosition(point, bearing, distance,index) {
        // 현재 위치의 위도와 경도를 라디안으로 변환
        bearing = this.toAngle(bearing); //360도로 계산
        const startLatRad = this.toRadians(point.lat);
        const startLngRad = this.toRadians(point.lng);
    
        // 이동 방향 각도를 라디안으로 변환
        const bearingRad = this.toRadians(bearing);
    
        // 이동 거리를 지구 반지름으로 나누어 각도로 변환
        const angularDistance = distance / this.EARTH_RADIUS;
    
        // 새로운 위치의 위도 계산
        const newLatRad = Math.asin(Math.sin(startLatRad) * Math.cos(angularDistance) +
            Math.cos(startLatRad) * Math.sin(angularDistance) * Math.cos(bearingRad));
    
        // 새로운 위치의 경도 계산
        const newLngRad = startLngRad + Math.atan2(
            Math.sin(bearingRad) * Math.sin(angularDistance) * Math.cos(startLatRad),
            Math.cos(angularDistance) - Math.sin(startLatRad) * Math.sin(newLatRad)
        );
    
        // 라디안 값을 도로 변환
        const newLatitude = this.toDegrees(newLatRad);
        const newLongitude = this.toDegrees(newLngRad);
        return {index:index, lat: newLatitude, lng: newLongitude };
    }

    /**
     * 두 점의 거리와 각도를 반환한다. point1에서 point2방향 
     * @param {Object} point1 
     * @param {Object} point2 
     * @returns angle : Number , distance : Number 
     */
    calculateAngleAndDistance(point1, point2) {
        // 현재 위치의 위도와 경도를 라디안으로 변환
        const startLatRad = this.toRadians(point1.lat);
        const startLngRad = this.toRadians(point1.lng);

        // 목표 위치의 위도와 경도를 라디안으로 변환
        const endLatRad = this.toRadians(point2.lat);
        const endLngRad = this.toRadians(point2.lng);

        // 두 지점 간의 각도 계산
        const deltaLng = endLngRad - startLngRad;
        const y = Math.sin(deltaLng) * Math.cos(endLatRad);
        const x =
            Math.cos(startLatRad) * Math.sin(endLatRad) -
            Math.sin(startLatRad) * Math.cos(endLatRad) * Math.cos(deltaLng);
        const angle = Math.atan2(y, x);
        const angleDegrees = this.toDegrees(angle);

        // 두 지점 간의 거리 계산
        const earthRadius = 6371000.0; // 지구 반지름 (단위: m)
        const deltaLat = endLatRad - startLatRad;
        const deltaLngRadians = endLngRad - startLngRad;
        const a =
            Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(startLatRad) *
                Math.cos(endLatRad) *
                Math.sin(deltaLngRadians / 2) *
                Math.sin(deltaLngRadians / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c;

        return { angle: angleDegrees, distance: distance };
    }
    
    toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }
    
    toDegrees(radians) {
        return radians * (180 / Math.PI);
    }

    toAngle(angle){
        return (angle%360) 
    }

   /**
    * 
    * @param {Number} sensorHeight 
    * @param {Number} imageHeight 
    * @param {Number} fov 
    * @returns 카메라 초점거리를 반환한다. 
    */
    calculateFocalLength(sensorHeight, imageHeight, fov) {
        const fovRadians = fov * (Math.PI / 180); //화각을 라디안으로 변환
    
        // 초점거리 계산 
        const focalLength = (sensorHeight * imageHeight) / (2 * Math.tan(fovRadians / 2));
    
        return focalLength;
    }

    /**
     * 
     * @param {Number} droneHeight 
     * @param {Number} sensorDimension 
     * @param {Number} focalLength 
     * @param {Number} imageDimension 
     * @returns gsd 값 반환 
     */
    calculateGSD(droneHeight, focalLength, sensorHeight, sensorWidth,  aspectRatioHeight,aspectRatioWidth) {
        // Calculate GSD
        const gsd = {
            aspectRatioHeight : aspectRatioHeight,
            aspectRatioWidth : aspectRatioWidth,
            GSDHeight : (droneHeight * sensorHeight) / (focalLength * aspectRatioHeight) , 
            GSDWidth : (droneHeight * sensorWidth) / (focalLength * aspectRatioWidth) ,
        }
        return gsd;
    }


    /**
     * 두 선분의 교차점 찾기 
     * @param {Object} line1 
     * @param {Object} line2 
     * @returns 
     */
    findIntersection(startPoint1,endPoint1 ,startPoint2,endPoint2) {
        const x1 = startPoint1.lng;
        const y1 = startPoint1.lat;
        const x2 = endPoint1.lng;
        const y2 = endPoint1.lat;
    
        const x3 = startPoint2.lng;
        const y3 = startPoint2.lat;
        const x4 = endPoint2.lng;
        const y4 = endPoint2.lat;
    
        const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
        if (denominator === 0) {
            // 선분이 평행하거나 겹치는 경우
            return null;
        }
    
        var t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denominator;
        var u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / denominator;
       
        if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
            const intersectionX = x1 + t * (x2 - x1);
            const intersectionY = y1 + t * (y2 - y1);
            return { lat: intersectionY, lng: intersectionX };
        }
    
        return null;
    }
    /**
     * 다각형의 무게중심을 구하는 로직 
     * @param {*} points 
     * @returns {lat , lng}
     */
    calculateCentroid(points) {
        let A = 0;  // 다각형의 면적
        let Cx = 0; // x축 무게중심
        let Cy = 0; // y축 무게중심
    
        const numPoints = points.length;
    
        for (let i = 0; i < numPoints; i++) {
            const x1 = points[i].lat;
            const y1 = points[i].lng;
            const x2 = points[(i + 1) % numPoints].lat;
            const y2 = points[(i + 1) % numPoints].lng;
    
            const crossProduct = (x1 * y2) - (x2 * y1);
    
            A += crossProduct;
            Cx += (x1 + x2) * crossProduct;
            Cy += (y1 + y2) * crossProduct;
        }
    
        A /= 2;
        Cx = Cx / (6 * A);
        Cy = Cy / (6 * A);
    
        return { lat: Cx, lng: Cy };
    }
   
    

    test(){
        const point1 = { lat: 37.56758, lng: 126.97663 };
        const point2 = { lat: 37.56808, lng: 126.97663 };
        //const point3 = { lat: 37.56758, lng: 126.97763 };
        const point4 = {lat : 37.56708, lng : 126.97763};
       
        var result2 = this.findFootOfPerpendicular(point1,point2,point4);

        var result2Angle = this.calculateAngleAndDistance(result2,point4);

       
        var result2Point1 = this.calculateNewPosition(point1,result2Angle.angle,result2Angle.distance,0);
        
        var result2Point2 = this.calculateNewPosition(point2,result2Angle.angle,result2Angle.distance,0);
        result2Point1
        result2Point2
    }
} 


export default MapUtils;