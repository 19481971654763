<template>
<!--  defaultButtonShowHeight + typeChangeButtonShowTop -->
  <div v-if="isPointShowButton" class="map-btn2" style="z-index:2; position: absolute" :style="[{ 'top': getMapPointTop + lngLatShowUnder + positionAndLienUnder + 'px'}, isLeftPosition == true? {'left' : defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'} ]">
    <div class="small" style="display: contents;">
      <img v-if="isPointCreate" src="@/assets/images/map/ic_map_point_create_selected.svg" class="button-icon" @click="pointShowModal(false)"/>
      <img v-if="!isPointCreate" src="@/assets/images/map/ic_map_point_create.svg" class="button-icon" @click="pointShowModal(true)"/>
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
// import {MapMixins} from "../mixins/MapMixins";
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";
export default {
  name: "MapPointShow",
  components: {},
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,
      //버튼 전체 값 region
      isPointCreate : false,
    };
  },
  mounted() {
  },
  created() {
  },
  computed : {
    ...mapState({
      isPointShowButton : state => state.map.isPointShowButton,
    }),
    getMapPointTop(){
      return this.$store.getters["map/getMapPointTop"];
    },
  },
  watch: {
  },
  methods: {
    onGetIsPointCreate(){
      return this.isPointCreate;
    },
    onSetIsPointCreate(isShow){
      this.isPointCreate = isShow;
    },
    pointShowModal(isShow){
      this.isPointCreate = isShow;
      this.$emit("map-is-point-create-show", this.isPointCreate)
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
