<template>
  <div class="modal team" style="position: fixed;">
    <div class="box weather" style="width: 100%;">
      <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
        <div style="display: flex; flex-direction: row;">
          <h1 class="bold">{{ $t("site-view-weather-title") }}</h1>
          <a @click="onReload" style="line-height: 2.5rem;"><!--새로고침 버튼-->
            <img src="@/assets/images/icon_svg/ic_refresh.svg" alt="icon" style="width: 1.5rem; margin-left: 10px;"/>
          </a>
        </div>
        <a @click="close" style="font-size: 2rem;"><i class="fas fa-times" style="width: 1.5rem;"></i></a>
      </div>
      <div style=" margin-bottom: 10px;">
        <p class="bold">
          {{ $t("site-view-weather-update") }} : <span>{{ updatedDate == ""  || updatedDate == undefined ? " - " : moment(updatedDate).format("YYYY.MM.DD HH:mm") }}</span>
        </p>
      </div>
      <div class="flexB">
        <div class="weatherWrap flex">
          <div class="cover" v-for="(data, i) in weatherList" :key="i">
            <h2>
              {{ data.weatherEventDataId.fcstDateTime.substr(8, 2) + "시" }}
            </h2>
            <img :src="require(`@/assets/images/icon_svg/${imgBinding(data)}.svg`)" alt="icon"/>
            <p>{{ data.temperatures == undefined ? '-' : data.temperatures.split(" ")[0] }}&#8451;</p>

            <img v-if="data.windDirection != undefined" src="@/assets/images/icon_svg/ic_w_wind.svg"  alt="icon"  :style="`transform: rotate(${data.windDirection.replace(' ', '')})`"/>
            <img v-if="data.windDirection == undefined" src="@/assets/images/icon_svg/ic-minus.svg"/>

            <p>
              {{ data.windDirection == undefined ? '-' : convertWindDirection(data.windDirection.split(" ")[0]) }}<br />
              {{ data.windDirection == undefined ? '-' : data.windSpeed }}
            </p>
            <img :src="require(`@/assets/images/icon_svg/${humidityBinding(data.humidity)}.svg`)" alt="icon"/>
            <!-- 습도 img 파일명 /  humidity_1 ~ humidity_5  -->
            <p>{{ data.humidity == undefined ? '-' : data.humidity }}</p>
            <!--<p>{{ data.rain == undefined || data.rain == "강수없음" ? '0mm' : data.rain }}</p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import windDirection from "@/util/windDirection";
export default {
  components: {  },
  props: {
    weatherList : {
      type: Array,
      default: null
    },
    updatedDate : {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      moment: moment,
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    close() {
      this.$emit("close");
    },
    humidityBinding(inputData) {
      if(inputData == undefined) {
        return "ic-minus";
      }

      let data = Number(inputData.split(" ")[0]);
      let humidity;
      if (0 <= data && data < 20) {
        humidity = 1;
      } else if (20 <= data && data < 40) {
        humidity = 2;
      } else if (40 <= data && data < 80) {
        humidity = 3;
      } else if (80 <= data && data < 90) {
        humidity = 4;
      } else if (90 <= data && data <= 100) {
        humidity = 5;
      }

      return "humidity_"+humidity;
    },
    imgBinding(data) {
      let imgName;
      if(data.thunderstroke == undefined|| data.rainCode == undefined || data.skyCode == undefined ) {
        return "ic-minus";
      }

      if (data.thunderstroke != "0") {
        imgName = "thunder";
      } else if (data.rainCode != "0") {
        imgName = "raincode_" + data.rainCode;
      } else if (data.skyCode != "0") {
        imgName = "skycode_" + data.skyCode;
      }
      return imgName;
    },
    convertWindDirection(data) {
      return windDirection[Math.floor((Number(data) + 11.25) / 22.5)];
    },
    onReload(){
      this.$parent.weatherList = [];
      this.$parent.getWeather();
    },
  },
};
</script>
