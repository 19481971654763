<template>
  <div v-if="isCenterShowButton" class="map-btn3" style="z-index:2; position: absolute" :style="[{ 'top':  getMapCenterTop + lngLatShowUnder + positionAndLienUnder + 'px' }, isLeftPosition == true? {'left' :defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'}]">
    <div class="small" style="display: contents" @click="moveCenter">
      <img v-if="isCenterSelected" src="@/assets/images/map/ic_site_center_selected.svg" class="button-icon"/>
      <img v-if="!isCenterSelected" src="@/assets/images/map/ic_site_center.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";

export default {
  name: "MapCenter",
  components: { },
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,
      //버튼 전체 값 region
      isCenterSelected : false,
    };
  },
  mounted() {
  },
  created() {
  },
  computed : {
    ...mapState({
      isCenterShowButton: state => state.map.isCenterShowButton,
    }),
    getMapCenterTop(){
      return this.$store.getters["map/getMapCenterTop"];
    },
  },
  watch: {
  },
  methods: {
    moveCenter() {
      this.$emit("map-center",this.isCenterSelected);
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
