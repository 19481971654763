<template>
  <div ondragstart="return false" class="kakao-map">
    <kakaoMap v-if = "loadMapName == 'kakao'" :mapType="mapType" :lng="mapCenter.lng" :lat="mapCenter.lat" :zoom = "zoomLevel" style="z-index:0; width: 100%; height: 100%; " ref="kakao"
    @ready-map="readyMap" @item-click="itemClick" @waypoint-create="createWaypointLatLng"
    @waypoint-change="changeWaypointLineLatLng" @waypoint-add="addWaypointLatlng"
    @mapping-create="createMappingLatLng" @mapping-change="changeMappingLatLng"
    @mapping-add="addMappingLatLng"/>

    <googleMap v-if = "loadMapName == 'google'" :mapType="mapType" :lng="mapCenter.lng" :lat="mapCenter.lat" :zoom = "zoomLevel" style="z-index:0; width: 100%; height: 100%; position: absolute;" ref="google"
    @ready-map="readyMap" @waypoint-create="createWaypointLatLng" @waypoint-change="changeWaypointLineLatLng" @waypoint-add="addWaypointLatlng"/>

    <!-- 버튼, 현위치 이동경로 버튼 -->
    <mapPositionAndLine @map-position-line-is-line="onClickShowLine"/>

    <!-- 버튼, 타입 변경 버튼 -->
    <mapTypeChange ref="mapTypeShow" @map-type-show-modal="mapTypeShowModal" @map-type-hide-modal="mapTypeHideModal"/>
    <!-- 화면, 타입 변경 -->
    <div v-if="isTypeChangeModal" class="map-type-change">
      <span> 지도유형 </span>
      <button @click="mapTypeHideModal(false)">
        <img src="@/assets/images/map/ic_map_close.svg" />
      </button>
      <img class="normal" :class="{ 'map-type-imag-active': typeSelectName == vueMapType.ID_NORMAL.name }" @click="typeSelectItemName(vueMapType.ID_NORMAL)"/>
      <img class="satellite" :class="{ 'map-type-imag-active': typeSelectName == vueMapType.ID_SATELLITE.name }" @click="typeSelectItemName(vueMapType.ID_SATELLITE)"/>
      <img class="terrain" :class="{ 'map-type-imag-active': typeSelectName == vueMapType.ID_TERRAIN.name }" @click="typeSelectItemName(vueMapType.ID_TERRAIN)"/>
    </div>

    <!-- 버튼, 주요지점 생성,수정 버튼  -->
    <mapPointShow ref="mapPointShow" @map-is-point-create-show="onPointIsCreateModal"/>
    <!-- 화면 주요지점 등록,수정 -->
    <div :class="[isPointCreate ? 'map-point-div' : 'map-point-modify-div', {'google' : loadMapName == 'google'}]" v-if="isPointCreate || isPointModify">
      <!--  주요지점 생성 화면 -->
      <template v-if="isPointCreate">
        <div class="select-image">
          <img class="create" src="@/assets/images/map/ic_map_point_create_position.svg"/>
        </div>
        <div class="map-point-create">
          <div class="map-point-title">
            <span>신규 위치점 등록</span>
          </div>
          <div class="flex select-group">
            <span>구분선택</span>
            <div class="selectCont">
              <div class="select" v-for="data in pointTypeList" :class="{active : pointSelectType == data}" @click="pointTypeChange(data)" :key="data">
                <span> {{$t(getPointTypeName(data )) }} </span>
              </div>
            </div>
          </div>

          <div class="insert-data">
            <span>참고사항</span>
            <textarea
                @keyup="lengthLimit"
                maxlength="201"
                v-model="pointMemo"
                :placeholder="[$t('point-register-textarea-placeholder-text')]"
            ></textarea>
            <div class="btn-wrap">
              <button class="medium point margin6" @click="pointCreateCall" style="background: rgb(0, 128, 255);border-color: rgb(0, 128, 255); border-radius:5px;">
                등록하기
              </button>
              <button class="medium" @click="onPointCreateModal(false)" style="border: 1px solid #ddd; color:#969696; border-radius:5px;">
                취소
              </button>
            </div>
          </div>
        </div>
      </template>

    </div>

    <!--현장 중앙으로 오는 버튼-->
    <mapCenter @map-center="moveCenter"/>

    <!--현장 중앙으로 오는 버튼-->
    <mapUserCenter @map-center="moveUserCenter"/>

    <!-- 버튼, 방공 데이터 표시 여부 버튼 -->
    <mapNoFlightArea @map-no-flight-area-show-modal="noFlightAreaShow" @map-no-flight-area-hide-modal="noFlightAreaShow"/>

    <!-- 버튼, 따라가기 버튼 -->
    <mapFollow @map-is-follow="onIsFollow"/>

    <!-- 드론 비행정보 알림 버튼 -->
    <mapFlightAlert @map-flight-alert-show-modal="FlightAlertModal" @map-flight-alert-hide-modal="FlightAlertModal"/>
    <!-- 드론 비행 알람 팝업: 종합상황판 전체 log -->
    <div v-if="isFlightAlertModal" class="box infoAlert" >
      <h4 style="font-size: 1.4rem; color:#fff; font-weight:300;margin-bottom: 10px;">접속로그</h4>
      <div class="logInfo-list" style="overflow-y: auto; height: 130px;max-height: 130px;background: #3c3c3c;">
        <div class="InfoDt" v-for="(data, i) in eventLogList" :key="'eventLog'+i"  >
          <div style="width: 100%;display: flex;align-items: center;">
            <img src="@/assets/images/icon_svg/menber_icon.png" alt="icon" style="margin-right: 5px;"/>
            <span>
              {{data.showTitle}} -
            </span>
            <span>
              {{ data.showDate}}
            </span>
          </div>
          <div style="width: 100%;padding-top: 5px;padding-left: 17px;">
            <span>
              {{data.showMessage}}
            </span>
          </div>
        </div>
        <div class="InfoDt" v-if="eventLogList.length == 0">
          <span>
            {{ $t("flight-alarm-none") }}
          </span>
        </div>
      </div>
    </div>

    <!-- 위치 정보 표시 -->
    <div v-if="isLngLatShow" class="mapTop" style="z-index:2;" :style="[{'width': lnglatPositionWidth - 20 + 'px'}, {'top' : defaultShowHeight + getLngLatShowUnder + 'px'}, isRightLngLat == true ? {'right' : getWeatherUnder + ( getPositionAndLienUnder) + 'px'} : {'left': 'calc(50% - 183px)'}]">
      <div class="locationInfo">
        <div class="latLngName">위도</div>
        <div class="latValue">{{lnglat.lat != null ? lnglat.lat.toFixed(9) : '-'}}</div>
        <div class="latLngName">경도</div>
        <div class="lngValue">{{lnglat.lng != null ? lnglat.lng.toFixed(9) : '-'}}</div>
      </div>
    </div>

    <!-- 현장 누적 카운트 -->
    <!-- 20240117 update -->
    <!-- <div v-if="isLogCountShow" class="box flex infoCount" style="display: flex;flex-direction: column;background: rgba(16, 16, 16, 0.82);border: 0;">
      <h2>전체현장 운영 현황</h2>
      <div class="cntIn">
        <div class="cntIn-img">
          <img src="@/assets/images/icon_svg/drone_icon.png" alt="icon" />
        </div>
        <b v-if="accountId=='komipo'"> 로봇운행횟수</b>
        <b v-else > {{ $t("overall-data-cnt-log-drone") }}</b>
        <span>{{ deviceLogCount || 0 }}</span>
      </div>
      <div class="cntIn">
        <div class="cntIn-img"><img src="@/assets/images/icon_svg/vod_icon.png" alt="icon" /></div>
        <b> {{ $t("top-select-total-view-flight-vod") }}</b>
        <span>{{ vodCount || 0 }}</span>
      </div>
      <div class="cntIn">
        <div class="cntIn-img"><img src="@/assets/images/icon_svg/menber_icon.png" alt="icon" /></div>
        <b> {{ $t("overall-data-cnt-log-member") }}</b>
        <span>{{ memberCount || 0 }}</span>
      </div>
    </div> -->

    <!--  전체 누적 카운트(종합상황판)  -->
    <div v-if="isAllLogCountShow" class="box flex allinfoCount" >
      <div class="flexB cntIn" style="height: auto; justify-items: center;">
        <img src="@/assets/images/icon_svg/ic_all_site.png" alt="icon" />
        <b> {{ $t("overall-data-event") }}</b>
        <span>{{ eventOpenCount || 0 }}</span>
      </div>
      <div class="flexB cntIn" style="height: auto; justify-items: center;">
        <img src="@/assets/images/icon_svg/drone_icon.png" alt="icon" />
        <b> {{ $t("overall-data-now-device") }}</b>
        <span>{{ deviceLiveCount || 0 }}</span>
      </div>
      <div class="flexB cntIn" style="height: auto; justify-items: center;">
        <img src="@/assets/images/icon_svg/menber_icon.png" alt="icon" />
        <b> {{ $t("overall-data-now-member") }}</b>
        <span>{{ workerLiveCount || 0 }}</span>
      </div>
      <div class="flexB cntIn" style="height: auto; justify-items: center;">
        <img src="@/assets/images/icon_svg/vod_icon.png" alt="icon" />
        <b> {{ $t("top-select-total-view-flight-vod") }}</b>
        <span>{{ vodLiveCount || 0 }}</span>
      </div>
    </div>

    <!-- 라이브 진행 정보 -->
    <div v-if="isAllLiveCountShow"  @click="openLiveModal" class="map-live-count">
      <h2><img src="@/assets/images/icon_svg/live-icon.png">실시간 라이브</h2>
      <h4>0</h4>
    </div>
    <div v-if="isLiveModalOpen" class="live-modal">
      <div class="vod-modal-content">
        <div class="modal-content-header">
          <h2>라이브영상 검색</h2>
          <button @click="closeLiveModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" /></button>
        </div>
        <div class="modal-content-body">
          <div class="modal-table">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>순번</th>
                  <th>디바이스 타입</th>
                  <th>디바이스 이름</th>
                  <th>소속</th>
                  <th>조종사</th>
                  <th>촬영시작일시</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td ><input type="checkbox" name ="liveCheckBox"></td>
                  <td></td>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-content-footer">
          <button>확인</button>
          <button @click="closeLiveModal">취소</button>
        </div>
      </div>
    </div>

    <!-- 날씨 정보 -->
    <WeatherInfo v-show="isShowWeather" ref="weatherInfo"/>

    <div ref="map-img-name-list" id="map-img-name-list" style="display: none;"></div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import {getPointTypeName} from "@/api/point";
import moment from "moment";
import axios from "axios";
import kakaoMap from "@/components/map/kakao/KaKaoMap";
import googleMap from "@/components/map/google/GoogleMap";

import MapDataType from "@/components/map/enum/MapDataType";
import MapType from "@/components/map/enum/MapType";
import MapImageName from "@/components/map/enum/MapImageName";
import MapCityType from "@/components/map/enum/MapCityType";

import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapLinePointData from "@/components/map/data/MapLinePointData";
import MapKoreaCityPosition from "@/components/map/city/MapKoreaCityPosition";
import WeatherInfo from "@/components/weather/WeatherInfo";

import mapPositionAndLine from "@/components/map/button/MapPositionAndLine";
import mapTypeChange from "@/components/map/button/MapTypeChange";
import mapPointShow from "@/components/map/button/MapPointShow";
import mapCenter from "@/components/map/button/MapCenter";
import mapUserCenter from "@/components/map/button/MapUserCenter";

import mapFlightAlert from "@/components/map/button/MapFlightAlert";
import mapNoFlightArea from "@/components/map/button/MapNoFlightArea";
import mapFollow from "@/components/map/button/MapFollow";
import icDeleteGr from "@/assets/images/icon_svg/ic_delete_gr.svg"
import icDrone from "@/assets/images/icon_svg/ic_ds_all_drone.svg"
import icMapDroneCameraAngle from "@/assets/images/map/ic_map_drone_camera_angle2.png";
import MapIcon from "@/components/map/data/MapIcon";
import icLNBRegisterBk from "@/assets/images/icon_svg/LNB_register_bk.svg";
import {mapState} from "vuex";

// import {ButtonIsShow} from "./mixins/ButtonIsShow";
// import {ShowUnder} from "./mixins/ShowUnder";
// import {MapMixins} from "./mixins/MapMixins";


function convert(deg) {
  return ((deg + 360) % 360);
}

// info html
function MapInfoHtmlEvent(data, eventCall) {
  let root = `<div id="info-root" class="box eventBox" style="z-index: 2000; width: 280px; height: 115px; padding: 10px; border-radius: 4px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);">
    <div class="boxCol2-1 flexB">
      <div class="contents" style="padding: 0; line-height: 1.8rem; max-width: 220px;">
        <p style="color:#ff5400;">No.${data.viewId} <span style="color: #919191; font-size: 1.2rem;">${data.managerDepartmentTitle}</span></p>
        <p><span class="bold">${data.title}</span></p>
        <p>${data.address}</p>
      </div>
      <button style="border: none;">
        <img id="info-icon" src="${icDeleteGr}"/>
      </button>
    </div>
    <div class="flexB">
    <div style="line-height: 36px; display: inline-flex;">
        <img id="drone-icon" src="${icDrone}"/>
        <span class="bold" style="color:#606060;">실시간 드론</span>
        <span id="drone-live-count-for-each-event" class="bold" style="color:#0790ff; margin-left: 10px">0</span>
    </div>
      <div class="buttonWrap" style="margin-top: 0">
        <button id="event-btn" class="pointLine" style="width: 3.6rem; height: 3.6rem; margin: 0; border-radius: 5px; font-size: 1.2rem;">
          선택
        </button>
      </div>
    </div>
  </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  let eventBtn = doc.getElementById("event-btn");
  let icon = doc.getElementById("info-icon");
  let liveDrone = doc.getElementById("drone-live-count-for-each-event");
  liveDrone.innerText = data.deviceCount;

  eventBtn.onmouseup = eventCall.bind(null, true);
  // if (eventBtn.addEventListener) {
  //   eventBtn.addEventListener('mouseup', eventCall.bind(null, true), true);
  // } else {
  //   eventBtn.attachEvent('on' + 'mouseup', eventCall.bind(null, true), true);
  // }

  icon.onmouseup = eventCall.bind(null, false );
  // if (icon.addEventListener) {
  //   icon.addEventListener('mouseup', eventCall.bind(null, false ), true);
  // } else {
  //   icon.attachEvent('on' + 'mouseup', eventCall.bind(null, false ), true);
  // }
  return doc.getElementById("info-root");
}

function MapInfoHtmlToUser(data, eventCall) {

  let root = `<div id="info-root" class="box eventBox" style="z-index: 2000; width: 200px; height: 100px; padding: 10px; border-radius: 4px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);">
    <div class="boxCol2-1">
      <div class="contents" style="padding: 0; line-height: 1.8rem; width: 160px;">
        <p><span class="bold" style="width: 160px;">${data.tag}</span></p>
        <div class="wid5050 flexB">
          <span style="font-size: 1.2rem;">${data.departmentName}</span>
          <span style="margin-left: 10px; font-size: 1.2rem;">${data.userName}</span>
        </div>
      </div>
      <div id="eventBtn" class="buttonWrap" style="margin-top: 0">
        <img id="infoIcon" alt="icon"/>
      </div>
    </div>
    <div style="margin-top: 5px; margin-left: 32px;">
      <p>
        <span class="lnglatData">위도</span>
        <span class="lnglatVal">${data.lat}</span>
      </p>
      <p>
        <span class="lnglatData">경도</span>
        <span class="lnglatVal">${data.lng}</span>
      </p>
    </div>
  </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  var target = doc.getElementById("eventBtn");
  var icon = doc.getElementById("infoIcon");
  icon.src = icDeleteGr
  if (target.addEventListener) {
    target.addEventListener('mouseup', eventCall, true);
  } else {
    target.attachEvent('on' + 'mouseup', eventCall, true);
  }
  return doc.getElementById("info-root");
}

function MapInfoHtmlToDroneDetail(data, eventCall, vue){
  eventCall;
  vue;
  let root = `<div id="info-root" style="z-index: 500; text-align: center; ">
          <div style="position: relative; display: flex; flex-wrap: wrap; flex-direction: column;">
            <div class="arrow_box eventBox" style="position: sticky; width: 260px; height: 110px; padding: 10px; margin-bottom: 15px; border-radius: 4px; box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5);">
              <div class="arrow_box_top">
                <div class="flexB">
                  <span id="info-name" class="bold" >${data.name}</span>
                  <img id="info-delete-icon" src="${icDeleteGr}" alt="icon"/>
                </div>
                <div class="flex">
                  <span id="info-user-department-name">${data.userDepartmentName}</span>
                  <span id="info-user-name">${data.userName}</span>
                </div>
              </div>
              <div style="background: #F5F5F5; border-radius: 3px; margin: 5px 0;">
                <div class="arrow_box_bottom">
                  <div class="flex">
                    <span class="lnglatData">고도</span>
                    <span id="info-height" class="lnglatVal">${data.distance == undefined ? 0 : data.height}m</span>
                  </div>
                  <div class="flex">
                    <span class="lnglatData">거리</span>
                    <span id="info-distance" class="lnglatVal">${data.distance == undefined ? 0 : data.distance}m</span>
                  </div>
                </div>
                <div class="arrow_box_bottom" >
                  <div class="flex">
                    <span class="lnglatData">위도</span>
                    <span id="info-lng" class="lnglatVal">${ (data.lat == undefined || data.lat == null) ? 0.0 : data.lat.toFixed(9)}</span>
                  </div>
                  <div class="flex">
                    <span class="lnglatData">경도</span>
                    <span id="info-lat" class="lnglatVal">${(data.lng == undefined || data.lng == null) ? 0.0 : data.lng.toFixed(9)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  let infoClose = doc.getElementById("info-delete-icon");
  infoClose.onmouseup = eventCall.bind(null, data, false );

  return doc.getElementById("info-root");
}

function MapInfoHtmlToPointModify(data, eventCall, vue) {
  let placeholder = vue.$t('point-register-textarea-placeholder-text');
  let root = `<div id="info-root" class="map-point-create" style="height:210px;">
          <div class="map-point-title" style="display:flex; justify-content: space-between;">
            <span>중요위치점 수정</span>
            <button style="border: none; line-height: 36px;">
              <img id="infoIcon"/>
            </button>
          </div>
          <div class="flex lnglatData">
            <span>위치좌표</span>
            <div class="locationInfo">
              <div class="latLngName">위도</div>
              <div class="latValue">${data.lat != null ? data.lat.toFixed(9) : '-'}</div>
              <div class="latLngName">경도</div>
              <div class="lngValue">${data.lng != null ? data.lng.toFixed(9) : '-'}</div>
            </div>

          </div>
          <div class="map-point-data">
            <span>구분선택</span>
            <div class="map-point-content">
              <span class="bold" style="color: #0790ff;"> ${data.typeName} </span>
            </div>
          </div>
          <div class="insert-data">
            <span>참고사항</span>
            <textarea id="pointData" @keyup="lengthLimit"  maxlength="201"  placeholder="${placeholder}">
            </textarea>
            <div id="buttonGroup" class="btn-wrap">
              <button id="pointModifySave" class="medium point" style="margin-right: 16px;">
                확인
              </button>
              <button id="pointModifyCancel" class="medium"">
                취소
              </button>
            </div>
          </div>
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  var infoRoot = doc.getElementById("infoRoot");
  var buttonGroup = doc.getElementById("buttonGroup");
  var pointModifySave = doc.getElementById("pointModifySave");
  var pointModifyCancel = doc.getElementById("pointModifyCancel");
  var pointData = doc.getElementById("pointData");
  var icon = doc.getElementById("infoIcon");
  icon.src = icDeleteGr

  if(!data.isOwner) {
    pointData.setAttribute("disabled", "disabled")
    buttonGroup.style = "display:none;";
    infoRoot.style = "height: 190px";
  }
  pointData.innerText = data.memo == null ? "" : data.memo;

  pointData.oninput = (event) => {
    data.memo = event.target.value;
  }

  pointModifySave.onmouseup = eventCall.bind(null, data, true );
  pointModifyCancel.onmouseup = eventCall.bind(null, data, false );

  icon.onmouseup = eventCall.bind(null, data, false );
  return doc.getElementById("info-root");
}

function MapInfoHtmlToPointDetail(data, eventCall, vue) {
  let placeholder = vue.$t('point-register-textarea-placeholder-text');
  eventCall;
  let root = `<div id="info-root" style="z-index: 2000;">
          <div style="position: relative; display: flex; flex-wrap: wrap; flex-direction: column;">
            <div class="arrow_box" style="position:sticky; text-align: center; width: 320px; height: 210px; padding: 20px; margin-bottom: 10px; border-radius: 4px; box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5);">
              <div class="arrow_box_top">
                <div class="flexB" style="margin-bottom:10px;">
                  <div class="flex">
                    <h1 id="info-name" class="tooltipData">${data.name}</h1>
                    <h3>
                      <span id="info-user-department-name">${data.userDepartmentName}</span>│
                      <span id="info-user-name">${data.userName}</span>
                    </h3>
                  </div>
                  <img id="info-delete-icon" src="${icDeleteGr}" alt="icon"  style="width:15px;"/>
                </div>
                <textarea id="info-point-data-change-textarea" style="font-size:1.3rem; height: 45px; width: 100%; margin: 0px; resize: none;"  disabled="disabled" @keyup="lengthLimit"  maxlength="201"  placeholder="${placeholder}">
                </textarea>
              </div>
              <div class="arrow_box_bottom" style="border-radius: 3px; margin: 10px 0;display: flex;flex-direction: column;}">
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;margin-bottom:8px;">
                  <span class="lnglatData" style="width: 50px;border-radius: 10px;background: #eee;color: #969696;">위도</span>
                  <span class="lnglatVal">${ (data.lat == undefined || data.lat == null) ? 0.0 : data.lat.toFixed(9)}</span>
                </div>
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
                  <span class="lnglatData" style="width: 50px;border-radius: 10px;background: #eee;color: #969696;">경도</span>
                  <span class="lnglatVal">${ (data.lng == undefined || data.lng == null) ? 0.0 : data.lng.toFixed(9)}</span>
                </div>
              </div>
              <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;margin-top: 15px;">
                <img id="info-point-data-change-btn" src="${icLNBRegisterBk}" data-is-modify="false" style="margin-right: 3px; cursor: pointer; display: ${data.isOwner?'block':'none'};"/>
                <button id="info-point-data-change-save-btn" style="display: none; margin:0; padding: 0 10px; height: 25px; font-size: 1.2rem; font-weight: 400;border:0;color: #fff;border-radius: 5px;background: rgb(0, 128, 255);" > 수정완료 </button>
              </div>
            </div>
          </div>
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  let infoPointDataChangeBtn = doc.getElementById("info-point-data-change-btn");
  let infoPointDataChangeSaveBtn = doc.getElementById("info-point-data-change-save-btn");
  let infoPointDataChangeTextarea = doc.getElementById("info-point-data-change-textarea");

  let infoClose = doc.getElementById("info-delete-icon");

  infoPointDataChangeTextarea.innerText = data.memo == null ? "" : data.memo;
  infoPointDataChangeTextarea.oninput = (event) => {
    data.memo = event.target.value;
  }

  infoPointDataChangeBtn.onmouseup = () => {
    let isModify = infoPointDataChangeBtn.dataset.isModify;
    let getModify = isModify == "true";
    infoPointDataChangeBtn.dataset.isModify = !getModify;
    getModify = !getModify;
    if(getModify) {
      infoPointDataChangeSaveBtn.style.display = "block";
      infoPointDataChangeTextarea.removeAttribute("disabled");
    } else {
      infoPointDataChangeSaveBtn.style.display = "none";
      infoPointDataChangeTextarea.setAttribute("disabled", "disabled");
    }
  }

  if(data.editMode) {
    infoPointDataChangeBtn.dataset.isModify = data.editMode;
    infoPointDataChangeSaveBtn.style.display = "block";
    infoPointDataChangeTextarea.removeAttribute("disabled");
  }

  infoClose.onmouseup = eventCall.bind(null, data, false );
  infoPointDataChangeSaveBtn.onmouseup = eventCall.bind(null, data, true );
  return doc.getElementById("info-root");
}


// info custom marker
function MapCustomMarkerEventCircle(data, eventCall) {
  eventCall;
  let root = `<div id="info-root" style="z-index: 100; text-align: center;">
  <!-- 드론 아이콘 -->
  <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
</div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}

function MapCustomMarkerEventCenter(data, eventCall) {
  eventCall;
  let root = `<div id="info-root" style="z-index: 100; text-align: center;">
  <!-- 드론 아이콘 -->
        <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
    </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}

function MapCustomMarkerUser(data, eventCall) {
  eventCall;
  let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

  if(mapCanvas == null) {
    return null;
  }

  let root = `<div id="info-root" class="map-icon-s" style="z-index: 100; text-align: center;">
          <div class="tooltipBack" style="background-size:120px 30px; width: 120px; height: 25px; padding: 5px 15px; margin-bottom: 3px;">
            <p id="info-name" class="bold">${data.name}</p>
          </div>
          <!-- 드론 아이콘 -->
          <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon" />
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}

function MapCustomMarkerStreamer(data, eventCall) {
  eventCall;
  let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

  if(mapCanvas == null) {
    return null;
  }

  let root = `<div id="info-root" style="z-index: 100; text-align: center;">
          <div class="tooltipBack" style="background-size:120px 30px; width: 120px; height: 25px; padding: 5px 15px; margin-bottom: 3px;">
            <p id="info-name" class="bold">${data.name}</p>
          </div>
          <!-- 드론 아이콘 -->
          <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon" style="width:30px;"/>
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}

function MapCustomMarkerDeviceDrone(data, eventCall) {
  eventCall;
  let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

  if(mapCanvas == null) {
    return null;
  }

  let root = `<div data-map-id="${data.mapId}" id="info-root" style="z-index: 500; text-align: center; width: 30px; height: 30px;">
          <div style="position: absolute; z-index: 300; left: -45px; top: -45px;">
              <div class="tooltipBack" style="  background-size:145px 50px; width: 145px; height: 45px; padding: 5px 15px; margin-bottom: 3px;">
                  <p id="info-name" class="bold">${data.name}</p>
                  <div class="flexB">
                    <span class="lnglatData bold" style="font-size: 1.2rem; color: #50b1ff;">${data.userName.length > 3 ? data.userName.substr(0,3)+"...": data.userName}</span>
                    <span class="lnglatData bold" style="font-size: 1.2rem; color: #808080;" alt="${data.userDepartmentName}">${data.userDepartmentName.length > 5 ? data.userDepartmentName.substr(0,5)+"..." : data.userDepartmentName}</span>
                  </div>
                </div>
          </div>

          <!-- 드론 아이콘 -->
          <div>
            <img id="${data.mapId}-gimbal-direction-img" class="gimbal-direction" style="visibility: hidden;" src="${icMapDroneCameraAngle}" alt="icon" />
            <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon"/>
          </div>
        </div>`;
  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  let img = doc.getElementById(data.mapId + "-info-img");
  let gimbalDirectionImg = doc.getElementById(data.mapId + "-gimbal-direction-img");
  let rotate = convert(Number(data.rotate));
  img.style.transform = "rotate(" + rotate + "deg)";
  if(data.gimbalDirection != null) {
    let gimbalDirection = convert(Number(data.gimbalDirection));
    let gimbal = rotate + gimbalDirection;
    gimbalDirectionImg.style.transform = "rotate(" + convert(gimbal) + "deg)";
    gimbalDirectionImg.style.visibility = "visible"
  }

  return doc.getElementById("info-root");
}

function MapCustomMarkerDeviceDroneDetail(data, eventCall) {
  eventCall;

  let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

  if(mapCanvas == null) {
    return null;
  }

  let root = `<div id="info-root" style="z-index: 500; text-align: center; width: 30px; height: 30px;">
          <!-- 드론 아이콘 -->
          <img id="${data.mapId}-gimbal-direction-img" class="gimbal-direction" style="visibility: hidden;" src="${icMapDroneCameraAngle}" alt="icon" />
          <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon" />
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  let img = doc.getElementById(data.mapId + "-info-img");
  let gimbalDirectionImg = doc.getElementById(data.mapId + "-gimbal-direction-img");
  let rotate = convert(Number(data.rotate));
  img.style.transform = "rotate(" + rotate + "deg)";
  if(data.gimbalDirection != null) {
    let gimbalDirection = convert(Number(data.gimbalDirection));
    let gimbal = rotate + gimbalDirection;
    gimbalDirectionImg.style.transform = "rotate(" + convert(gimbal) + "deg)";
    gimbalDirectionImg.style.visibility = "visible"
  }

  return doc.getElementById("info-root");
}

function MapCustomMarkerPlayerDrone(data, eventCall) {
  eventCall;
  let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

  if(mapCanvas == null) {
    return null;
  }

  let root = `<div id="info-root" style="z-index: 500; text-align: center; width: 30px; height: 30px;">
          <!-- 드론 아이콘 -->
          <img id="${data.mapId}-gimbal-direction-img" class="gimbal-direction" style="visibility: hidden;" src="${icMapDroneCameraAngle}" alt="icon" />
          <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon" style="${data.imageName == 'ID_ICON_STREAMER' ? 'width:30px;' : ''}" />
        </div>`;
  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  let img = doc.getElementById(data.mapId + "-info-img");
  let gimbalDirectionImg = doc.getElementById(data.mapId + "-gimbal-direction-img");
  let rotate = convert(Number(data.rotate));
  img.style.transform = "rotate(" + rotate + "deg)";
  if(data.gimbalDirection != null) {
    let gimbalDirection = convert(Number(data.gimbalDirection));
    let gimbal = rotate + gimbalDirection;
    gimbalDirectionImg.style.transform = "rotate(" + gimbal + "deg)";
    gimbalDirectionImg.style.visibility = "visible"
  }

  return doc.getElementById("info-root");
}

function MapCustomMarkerPoint(data, eventCall) {
  eventCall;
  let root = `<div id="info-root" style="text-align: center;">
          <div class="tooltipBack" style="background-size: 70px 25px; width: 70px; height: 24px; line-height: 23px;">
            <p class="bold" style="color:#ff8800;">${data.name}</p>
          </div>
          <!-- 포인트 아이콘 -->
          <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}

function MapCustomMarkerPointDetail(data, eventCall) {
  eventCall;
  let root = `<div id="info-root" style="text-align: center;">
          <div class="tooltipBack" style="visibility: hidden; background-size: 70px 25px; width: 70px; height: 24px; line-height: 23px;">
            <p class="bold" >${data.name}</p>
          </div>
          <!-- 포인트 아이콘 -->
          <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");

  return doc.getElementById("info-root");
}

function MapCustomMarkerCircle(data){
  let root = `<div id="info-root">
    <img id="${data.mapId}-info-img" style="transform: rotate(0deg);">
    <div id="info-name" class="circle" style="background-color:${data.color};">${data.name}</div>
  </div>`

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}

function MapCustomMarkerWaypoint(data){
  let root = `<div id="info-root">
    <div id="info-name" class="showName waypoint">${data.name}</div>
    <img id="${data.mapId}-info-img" src="${data.icon}" style="transform: rotate(0deg);">
  </div>`

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}

function MapCustomMarkerMapping(data){
  let root = `<div id="info-root">
    <div id="info-name" class="showName waypoint">${data.name == null ? '' : data.name}</div>
    <img  src="${data.icon}" style="transform: rotate(0deg); width: 20px; height: 20px;">
  </div>`

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}

/**
 * @param data: MapMarkerData
 */
function MapCustomMarkerStation(data) {
  let root = `<div id="info-root" class="map-icon-s" style="z-index: 100; text-align: center;">
          <!-- 스테이션 아이콘 -->
          <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
        </div>`;

  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-root");
}



export default {
  name: "MapView",
  mixins : [],
  components: { kakaoMap,
    googleMap,
    WeatherInfo,
    mapTypeChange,
    mapPositionAndLine,
    mapPointShow ,
    mapCenter,
    mapUserCenter,
    mapFlightAlert,
    mapNoFlightArea,
    mapFollow
  },
  props: {
    mapType : {
      type: Object,
      default() {
        return MapType.ID_SATELLITE
      },
    },
    defaultZoomLevel : {
      type : Number,
      default : 6,
    },
    loadMapName: {
      type: String,
    },
    eventId : {
      type : String,
      default: null,
    },
    isLeftButtonPosition : {
      type : Boolean,
      default : false,
    },
    isRightAddVideoPosition : {
      type : Boolean,
      default : false,
    },
    isRightLngLat : {
      type : Boolean,
      default : true,
    },
  },
  data() {
    return {
      moment: moment,
      isLngLatShow : false,
      mapDomWidth : null,
      //버튼 전체 값 region
      zoomLevel : this.defaultZoomLevel,
      isFollow : true,
      //버튼 전체 값 endregion

      //날씨 정보 region
      weatherUnder : 0,
      isShowWeather : false,
      weatherInfo : null,
      weatherPositionWidth : 130,
      lnglatPositionWidth : 280,
      //날씨 정보 endregion

      //현위치, 라인 관련 변수 region
      isShowLine: false,
      //현위치, 라인 관련 변수 endregion

      //맵 타입 변경 관련 변수 region
      vueMapType : MapType,
      typeSelectName: this.mapType.name,
      isTypeChangeModal : false,
      //맵 타입 변경 관련 변수 endregion

      //주요지점 관련 변수 region
      //isPointShowButton:false,
      pointButtonShowTop: 0,
      pointSelectType:'Caution',
      pointTypeList : ['Home','Caution', 'Danger', 'Search', 'Target', 'Station'],
      isPointCreate : false,
      isPointModify : false,
      pointMemo : null,
      pointData : null,

      //드론 비행 알림 공지 팝업
      isFlightAlertModal: false,
      noticeList : [],
      eventLogList: [],

      //현장 누적 카운트
      isLogCountShow : false,
      deviceLogCount: 0,
      deviceLiveCount: 0,
      workerLiveCount: 0,
      vodCount: 0,
      vodLiveCount: 0,
      memberCount: 0,
      eventOpenCount: 0,

      //종합현황판 누적 카운트
      isAllLogCountShow : false,

      //라이브 카운트
      isAllLiveCountShow:false,
      //맵 관련 region
      mapCenter: {
        //lat: 36.09510666745097,
        //lng: 127.97547912608519
        lat: this.latDefault,
        lng: this.lngDefault
      },
      latDefault: 36.09510666745097, //기본 좌표
      lngDefault: 127.97547912608519,//기본 좌표


      mapReady : false,
      //각각의 데이터 항목의 보이는 데이터 여부 값들
      isDroneShow : true,
      isControllerShow : true,
      isUserShow : true,
      isPointShow : true,

      //맵 전체 데이터 관리
      redColor : "#ff4600",
      grayColor : "#24FF7C",
      missionLineMainColor : "#3692ff",
      missionLineSubColor : "#76b1ff",
      vodLiveColor : ["#ff4600", "#2B79EE", "#FF7F33" , "#9E30FF"] ,  //{0 : "#ff4600", 1 : "#2B79EE", 2 : "#FF7F33" , 3 :"#9E30FF"},

      lnglat: {
        lng : null,
        lat : null,
      },
      //영상 관련 데이터
      playData : {
        markerData : null,
        LineData : null,
        LineFullData : null,
      },
      dataUrl : null,

      //모달
      isLiveModalOpen: false,
    };
  },
  mounted() {
    this.mapInstance.onMapTypeChange(this.mapType);
    let keys = Object.keys(MapIcon);
    let imgNameList = this.$refs["map-img-name-list"];
    keys.forEach((key)=>{
      let icon = MapIcon[key].icon;
      if(icon != undefined && icon != null) {
        let findIndex = icon.lastIndexOf( ".svg");
        // if(findIndex === -1) {
        //   findIndex = icon.lastIndexOf( ".png");
        // }
        if(findIndex != -1) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          var base_image = new Image();
          base_image.src = icon;
          base_image.onload = function() {
            canvas.width = base_image.width;
            canvas.height = base_image.height;
            ctx.drawImage(base_image, 0, 0);
            canvas.setAttribute("data-map-img-id",key);
            imgNameList.append(canvas);
          }
        }
      }
    });
  },
  created() {
    // this.$IsShowEventBus.$emit("isLeftButtonPosition", this.isLeftButtonPosition);
    this.$store.dispatch("map/IS_LEFT_BUTTON_POSITION", this.isLeftButtonPosition);
    this.$store.dispatch("map/IS_RIGHT_ADD_VIDEO_POSTION", this.isRightAddVideoPosition);
    this.zoomLevel = this.defaultZoomLevel;
  },
  computed : {

    ...mapState({
      defaultShowHeight : state => state.map.defaultShowHeight,
      topDefault : state => state.map.topDefault,
      defaultButtonShowHeight : state => state.map.defaultButtonShowHeight,


      isLngLatShowUnder : state => state.map.isLngLatShowUnder,
      isPositionAndLienUnder : state => state.map.isPositionAndLienUnder,

      positionAndLienUnder : state => state.map.positionAndLienUnder,
      defaultLineValue : state => state.map.defaultLineValue,
      lngLatShowUnder : state => state.map.lngLatShowUnder,

      accountId: state => state.store.accountId,

    }),
    getIsLngLatShowUnder(){
      return this.isLngLatShowUnder;
    },
    getLngLatShowUnder(){
      return this.isLngLatShowUnder ? 53 : 0;
    },
    getPositionAndLienUnder(){
      return this.isPositionAndLienUnder ? 0 : 180;
    },
    getWeatherUnder(){
      var defaultLineValue = this.defaultLineValue;
      if(this.isShowWeather && this.isLeftButtonPosition) {
        defaultLineValue = defaultLineValue + this.defaultLineValue + this.weatherPositionWidth;
      }
      return defaultLineValue;
    },
    mapInstance : {
      get: function () {
        var instance = null;
        switch (this.loadMapName){
          case "mapbox":
            instance = this.$refs.mapBox;
            break;
          case "kakao":
            instance = this.$refs.kakao;
            break;
          case "google":
            instance = this.$refs.google;
            break;
        }
        return instance;
      },
    },
  },
  watch: {
    pointData : function () {
      if(this.pointData != null){
        this.pointSelectType == this.pointData.type;
      }
    },
    mapDomWidth(change){
      if(change > (540 + 30 + 30 + 134 + 30)) {
        this.$store.dispatch("map/IS_POSITION_AND_LIEN_UNDER",false);
        this.$store.dispatch("map/IS_LNG_LAT_SHOW_UNDER",false);
      } else if(change <= (540 + 30 + 30 + 134 + 30) && change > (540 + 30 + 30)) {
        this.$store.dispatch("map/IS_POSITION_AND_LIEN_UNDER",true);
        this.$store.dispatch("map/IS_LNG_LAT_SHOW_UNDER",false);
      } else {
        this.$store.dispatch("map/IS_LNG_LAT_SHOW_UNDER",true);
      }

    },
    lnglat : {
      deep : true,
      handler() {
      },
    },
  },
  methods: {
    onGetMapInfoHtmlContent(dataType, markerData, data, event){
      var rootDiv = null;
      switch(markerData.mapDataType){
        case MapDataType.ID_SITE_CIRCLE :
          rootDiv = MapInfoHtmlEvent(data, event.bind(null, data.eventId, data), this);
          break;
        case MapDataType.ID_POINT:
          rootDiv = MapInfoHtmlToPointDetail(data, event, this);
          break;
        case MapDataType.ID_MAP_INFO_USER:
          MapInfoHtmlToUser(data, event.bind(null, data));
          break;
        case MapDataType.ID_MAP_INFO_DRONE:
          rootDiv = MapInfoHtmlToDroneDetail(data, event.bind(null, data));
          break;
        case MapDataType.ID_MAP_INFO_POINT:
          rootDiv = MapInfoHtmlToPointModify(data, event, this);
          break;
      }
      return rootDiv;
    },
    onGetHtmlContent(markerData) {
      let rootDiv = null;
      let icon = MapIcon[markerData.imageName];

      if(icon != null && icon != undefined) {
        markerData.icon = icon.icon;
      }

      switch(markerData.mapDataType){
        case MapDataType.ID_SITE:
          rootDiv = MapCustomMarkerEventCenter(markerData, null);
          break;
        case MapDataType.ID_STATION:
          rootDiv = MapCustomMarkerEventCenter(markerData, null);
          break;
        case MapDataType.ID_CITY:
          rootDiv = MapCustomMarkerCircle(markerData);
          break;
        case MapDataType.ID_PLAYER_MARKER:
          rootDiv = MapCustomMarkerPlayerDrone(markerData);
          break;
        case MapDataType.ID_USER:
        case MapDataType.ID_CONTROLLER:
          rootDiv = MapCustomMarkerUser(markerData);
          break;
        case  MapDataType.ID_STREAMER :
          rootDiv =   MapCustomMarkerStreamer(markerData);
        break
        case MapDataType.ID_DRONE:
          if(markerData.isMain){
            rootDiv = MapCustomMarkerDeviceDroneDetail(markerData);
          } else {
            rootDiv = MapCustomMarkerDeviceDrone(markerData);
          }
          break;
        case MapDataType.ID_POINT:
          if(markerData.isMain){
            rootDiv = MapCustomMarkerPointDetail(markerData);
          } else {
            rootDiv = MapCustomMarkerPoint(markerData, null);
          }
          break;
        case MapDataType.ID_WAYPOINT:
          rootDiv = MapCustomMarkerWaypoint(markerData, null);
          break;
        case MapDataType.ID_SITE_CIRCLE:
          rootDiv = MapCustomMarkerEventCircle(markerData, null);
          break;
        case MapDataType.ID_MAPPING_MARKER: //mapping 전용 마커
          rootDiv = MapCustomMarkerMapping(markerData, null);
          break;
        // 240814 - 신현호
        case MapDataType.ID_SCHEDULE_STATION:
          rootDiv = MapCustomMarkerStation(markerData);
          break
        default: {
          rootDiv = null;
        }
      }
      return rootDiv;
    },

    onCreateMapMarker(id, lng, lat, mapDataType) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = lng;
      mapMarkerData.lat = lat;
      mapMarkerData.mapDataType = mapDataType;

      // 기존에 마커가 있다면 수정을함.
      if (this.onIsMarker(mapMarkerData.mapDataType, id)) {
        this.onModifyMarkerAt(mapMarkerData.mapDataType, mapMarkerData);
      } else {
        this.onCreateMarker(mapMarkerData.mapDataType, mapMarkerData);
      }
    },
    onSetDraggable(draggable) {
      // 마우스 드래그로 지도 이동 가능여부를 설정합니다
      this.mapInstance.onSetDraggable(draggable);
    },
    onSetLngLat(lng,lat){
      this.lnglat.lat = lat;
      this.lnglat.lng = lng;
    },
    setLayout(){
      if( this.mapInstance != null) {
        this.mapInstance.setLayout();
      }
    },
    setWeatherInfoEventId(eventId) {
      if(this.$refs.weatherInfo != undefined) {
        this.$refs.weatherInfo.setEventId(eventId);
      }
    },
    getWeatherInfo(){
      if(this.$refs.weatherInfo != undefined) {
        return this.$refs.weatherInfo.getWeatherInfo();
      }
      return null;
    },
    onShowWeatherInfo(){
      this.isShowWeather = true;
    },
    onIsWeatherClick (){
      this.$emit("weather-click");
    },
    setNotZoomable(){
      this.mapInstance.setNotZoomable()
    },
    addWaypointLatlng(index,latlng){
      this.$emit("waypoint-add", index, latlng);
    },
    changeWaypointLineLatLng(index, latlng){
      this.$emit("waypoint-change", index, latlng);
    },
    createWaypointLatLng(latlng){
      this.$emit("waypoint-create",latlng)
    },

    onWaypointMarkerIdSort(){
      this.mapInstance.onWaypointMarkerIdSort();
    },
    onWaypointLineIdSort(){
      this.mapInstance.onWaypointLineIdSort();
    },
    createMappingLatLng(latlng){
      this.$emit("mapping-create",latlng)
    },
    changeMappingLatLng(index,latlng){
      this.$emit("mapping-change",index,latlng)
    },
    addMappingLatLng(index,latlng){
      this.$emit("mapping-add",index,latlng)
    },
    updateMapping(latlng, polygonPath){
      this.$emit("mapping-update",latlng,polygonPath)
    },
    onMappingMarkerIdSort(){
      this.mapInstance.onMappingMarkerIdSort();
    },
    onMappingLineIdSort(){
      this.mapInstance.onMappingLineIdSort();
    },
    getReadyMap(){
      return this.mapReady;
    },
    readyMap(isReady){
      this.mapReady = isReady;
      this.$emit("ready-map",this.mapReady)
    },
    getMapCenter(){
      return this.mapInstance.onGetCenterPosition();
    },
    moveCenter(){
      this.$emit("map-move-center");
    },
    moveUserCenter(){
      this.$emit("map-move-user-center");
    },
    onSetIsWindowInfo(mapDataType){
      this.mapInstance.onSetIsWindowInfo(mapDataType);
    },

    itemClick(id,type,isMain){
      this.$emit('item-click',id,type,isMain);
    },
    //textarea 글자 수 제한
    lengthLimit() {
      if (this.pointMemo.trim().length >= 201) {
        this.pointMemo = this.pointMemo.slice(0, -1);
        alert(this.$t("alert-message-characters-over"));
      }
    },

    //맵 위 버튼 함수 region
    /**
     * 기본 안보여짐
     * 현 위치, 이동 경로 버큰 보이게 하기
     */
    onShowButtonPosition() {
      this.$store.dispatch("map/IS_POSITION_AND_LIEN_SHOW_BUTTON", true);
    },

    /**
     * 맵 포지션, 라인 버튼 클릭
     * 화면에 따라 기본 라인 보여줄수 있음
     * 이동경로 선택 시 라인 보이게 만듬
     */
    onClickShowLine(isShowLine) {
      if (this.isShowLine != isShowLine) {
        this.isShowLine = isShowLine;
        if(this.isShowLine == true) {
          const lineData = new MapLineData();
          lineData.mapId = MapDataType.ID_DRONE;
          lineData.isShow = this.isDroneShow && this.isShowLine;
          this.mapInstance.onModifyLineList(lineData);

          lineData.mapId = MapDataType.ID_CONTROLLER;
          lineData.isShow = this.isControllerShow && this.isShowLine;
          this.mapInstance.onModifyLineList(lineData);

          lineData.mapId = MapDataType.ID_USER;
          lineData.isShow = this.isUserShow && this.isShowLine;
          this.mapInstance.onModifyLineList(lineData);

          lineData.mapId = MapDataType.ID_STREAMER;
          lineData.isShow = this.isUserShow && this.isShowLine;
          this.mapInstance.onModifyLineList(lineData);
        } else {
          this.mapInstance.onSetLineAllIsShow(this.isShowLine);
        }
      }
    },
    onOverlayColorChange(MapDataType, colors){
      this.mapInstance.onOverlayColorChange(MapDataType, colors);
    },
    /**
     * 맵타입 변경 버튼(아이콘모양) 보이게 하기
     */
    onShowButtonTypeChange() {
      // this.$IsShowEventBus.$emit("isTypeChangeShowButton", true);
      this.$store.dispatch("map/IS_TYPE_CHANGE_SHOW_BUTTON", true);
    },

    /**
     * 포이트 생성 변경 버튼(아이콘모양) 보이게 하기
     */
    onShowButtonPoint(){
      // this.$IsShowEventBus.$emit("isPointShowButton", true);
      this.$store.dispatch("map/IS_POINT_SHOW_BUTTON", true);
    },
    /**
     * 현장 중심 버튼(아이콘 모양) 보이게 하기
     * */
    onShowCenterPoint(){
      // this.$IsShowEventBus.$emit("isCenterShowButton", true);
      this.$store.dispatch("map/IS_CENTER_SHOW_BUTTON", true);
    },
    /**
     * 현장 중심 버튼(아이콘 모양) 보이게 하기
     * */
    onShowUserCenterPoint(){
      // this.$IsShowEventBus.$emit("isCenterShowButton", true);
      this.$store.dispatch("map/IS_USER_CENTER_SHOW_BUTTON", true);
    },
    /**
     * 현장 중심 버튼(아이콘 모양) 보이게 하기
     * */
    onShowLngLat(){
      this.isLngLatShow = true;
    },
    /**
     * 항공 금지 구역 버튼(아이콘 모양) 보이게 하기
     * */
    onShowButtonNoFlightArea(){
      // this.$IsShowEventBus.$emit("isNoFlightAreaShowButton", true);
      this.$store.dispatch("map/IS_NO_FLIGHT_AREA_SHOW_BUTTON", true);
    },

    /**
     * 맵타입 변경 버튼(아이콘모양) 보이게 하기
     */
    onShowButtonFollow() {
      // this.$IsShowEventBus.$emit("isFollowShowButton", true);
      this.$store.dispatch("map/IS_FOLLOW_SHOW_BUTTON", true);
    },
    onShowAllButton(isShow){
      // this.$store.dispatch("map/IS_FOLLOW_SHOW_BUTTON", isShow);
      // this.$store.dispatch("map/IS_NO_FLIGHT_AREA_SHOW_BUTTON", isShow);
      //this.$store.dispatch("map/IS_USER_CENTER_SHOW_BUTTON", isShow);
      this.$store.dispatch("map/IS_CENTER_SHOW_BUTTON", isShow);
      // this.$store.dispatch("map/IS_POINT_SHOW_BUTTON", isShow);
      this.$store.dispatch("map/IS_TYPE_CHANGE_SHOW_BUTTON", isShow);
      // this.$store.dispatch("map/IS_POSITION_AND_LIEN_SHOW_BUTTON", isShow);
    },

    onIsFollow(value){
      this.isFollow = value;
      this.$emit("map-is-follow",value);
    },
    //맵 위 버튼 함수 endregion

    /**
     * 항공 금지 구역 버튼(아이콘 모양) 맵 표시 여부
     * @param isShow null, true, false (null: 현재 값에 반대 값 넣기 )
     */
    noFlightAreaShow(isShow) {
      isShow;
    },

    /**
     * 드론 비행 알림 버튼(아이콘모양) 보이게 하기
     */
    onShowButtonInfoChange() {
      // this.$IsShowEventBus.$emit("isFlightAlertShowButton", true);
      this.$store.dispatch("map/IS_FLIGHT_ALERT_SHOW_BUTTON", true);
    },

    /**
     * 현장 누적 카운트 보이게 하기
     */
    onShowLogCount() {
      this.isLogCountShow = true;
    },

    onShowAllLogCount() {
      this.isAllLogCountShow = true;
    },
    //맵 타입 modal 함수 region
    /**
     * 맵 타입 변경 레이아웃 보이기 및 닫기
     * @param isShow null, true, false (null: 현재 값에 반대 값 넣기 )
     */
    mapTypeShowModal(isShow){
      this.isTypeChangeModal = isShow;
    },
    mapTypeHideModal(isShow){
      if(!isShow && this.isTypeChangeModal) {
        this.isTypeChangeModal = isShow;
        this.$refs.mapTypeShow.hideModal();
      }
    },
    /**
     * 맵 타입 변경 이미지 선택
     */
    typeSelectItemName(selectedMapType) {
      if(this.typeSelectName == selectedMapType.name) {
        return;
      }
      this.typeSelectName = selectedMapType.name;
      this.mapInstance.onMapTypeChange(selectedMapType);
    },

    onSetDeviceLogCount(deviceLogCount){
      this.deviceLogCount = deviceLogCount;
    },
    onSetVodCount(vodCount){
      this.vodCount = vodCount;
    },
    onEventJoinWorkerCount(memberCount){
      this.memberCount = memberCount;
    },
    onEventLogList(eventLogList){
      this.eventLogList = eventLogList;
    },
    //맵 위 modal 함수 endregion


    //도시별 마커 표시 및 데이터 region
    onCityMarkerCreate(mapCityType, markerDataList){
      switch (mapCityType) {
        case MapCityType.ID_KOREA :
          var data = Object.assign({}, MapKoreaCityPosition);
          var positionKey = Object.keys(data);
          markerDataList.forEach(item => {
            var filter = positionKey.filter(find => {
              return "ID_"+item.id.toUpperCase() == find
            });
            if(filter.length > 0){
              let key = filter[0];
              item.lng = data[key].point.lng;
              item.lat = data[key].point.lat;
              item.color = data[key].color;
              item.mapDataType = MapDataType.ID_CITY;
              item.html = this.onGetHtmlContent(item);
              this.mapInstance.onCreateMarker(item);
            }
          });

          break;
      }
    },
    //도시별 마커 표시 및 데이터 endregion

    //주요지점 관련 함수 region
    /**
     * 포인트 타입에 따른 이름 가져오기, 언어 값
     * @param type 포인트 타입
     * @returns 언어설정된 문자열
     */
    getPointTypeName(type){
      return getPointTypeName(type);
    },

    /**
     * 포인트 타엽 변경
     * @param select 생성 할 포인트 타입 변경
     */
    pointTypeChange(select) {
      this.pointSelectType = select;
    },

    /**
     * 포인트 생성 함수, 이벤트 연결
     */
    pointCreateCall(){
      var position = this.mapInstance.onGetCenterPosition();
      let sendData = {
        lng : position.lng,
        lat : position.lat,
        type : this.pointSelectType,
        memo : this.pointMemo
      }
      this.$emit("point-create", sendData);
      this.pointMemo = null;
      this.onPointCreateModal(false);
    },
    /**
     * 포인트 버튼 이벤트 연결, 생성 모달 보이기/가리기
     */
    onPointIsCreateModal(isCreateShow){
      this.isPointCreate = isCreateShow;
      if(this.isPointCreate) {
        this.pointMemo = null;
      }
      this.$emit("map-point-create-modal-show", this.isPointCreate);
    },

    /**
     * 맵 외부 연결
     * @param isShow null, true, false (null: 현재 값에 반대 값 넣기 )
     */
    onPointCreateModal(isShow) {
      this.isPointCreate = isShow;
      this.$refs.mapPointShow.onSetIsPointCreate(isShow);
      this.$emit("map-point-create-modal-show", this.isPointCreate);
    },
    /**
     * 맵 외부 연결
     * @param isShow null, true, false (null: 현재 값에 반대 값 넣기 )
     */
    onGetIsPointCreate() {
      return this.isPointCreate;
    },
    //포인트 관련 함수 endregion

    // 맵 관련 함수 region
    /**
     * 주소를 이용한 좌표 가져온 후 센터 이동
     * 카카오 api 이용
     * @param addr
     */
    onAddressToMoveCenter: function (addr) {
      const url = "https://dapi.kakao.com/v2/local/search/address.json?query=" + addr;
      axios.get(url, {
        headers : {
          "Authorization":"KakaoAK 469a7fd4cb1f60077561c136389b0b8c", //8b2ba5acde398e492c2a0e5f05d8b2a8
          "content-type":"application/json"
        }
      })
          .then((resp) => {
            var results = resp.data.documents;
            if (results.length > 0) {
              var longitude = results[0].x;
              var latitude = results[0].y;
              let point = {
                lng : longitude,
                lat : latitude,
              }
              this.mapCenter = point;
              //TODO 맵 위치 이동
              this.mapInstance.onSetCenterPosition(this.mapCenter);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },

    onUserCenterPosition : function(lng,lat) {
      this.onLngLatToMoveCenter(lng,lat);
    },
    /**
     * 좌표 값으로 위치 맵 센터 이동
     * @param lng
     * @param lat
     */
    onLngLatToMoveCenter: function (lng, lat) {
      let point = {
        lng : lng,
        lat : lat,
      }
      this.mapCenter = point;
      if(this.mapInstance != undefined) {
        this.mapInstance.onSetCenterPosition(this.mapCenter);
      }
    },

    /**
     * default 좌표로 이동
     * */
    moveToDefaultCoordinates: function (){
      let point = {
        lng : this.lngDefault,
        lat : this.latDefault,
      }
      this.mapCenter = point;
      if(this.mapInstance != undefined) {
        this.mapInstance.onSetCenterPosition(this.mapCenter);
      }
    },

    /**
     * 맵 드론 비행 알람 팝업 보여주기
     * @param isShow null, true, false (null: 현재 값에 반대 값 넣기 )
     */
    FlightAlertModal(isInfoShow) {
      this.isFlightAlertModal = isInfoShow;
    },


    /**
     * 맵 전체 데이터 제거
     */
    onClearMapData() {
      this.mapInstance.onDeleteAllData();
    },

    /**
     * 타입별 데이터 보여짐 여부 설정
     * @param mapDataType
     * @param isShow
     */
    onSetIsShowDataType(mapDataType, isShow){
      switch (mapDataType) {
        case MapDataType.ID_DRONE:
          this.isDroneShow = isShow;
          break;
        case MapDataType.ID_CONTROLLER:
          this.isControllerShow = isShow;
          break;
        case MapDataType.ID_USER:
          this.isUserShow = isShow;
          break;
        case MapDataType.ID_POINT:
          this.isPointShow = isShow;
          break;
      }

      const markerData = new MapMarkerData();
      markerData.mapId = mapDataType;
      markerData.isShow = isShow;

      const lineData = new MapLineData();
      lineData.mapId = mapDataType;
      lineData.isShow = isShow;

      this.mapInstance.onModifyMarkerList(markerData);
      this.mapInstance.onModifyLineList(lineData);
    },

    getIsShowDataType(mapDataType){
      var result = true;
      switch (mapDataType) {
        case MapDataType.ID_DRONE:
          result = this.isDroneShow;
          break;
        case MapDataType.ID_CONTROLLER:
          result = this.isControllerShow;
          break;
        case MapDataType.ID_USER:
          result = this.isUserShow;
          break;
        case MapDataType.ID_POINT:
          result = this.isPointShow;
          break;
      }
      return result;
    },

    /**
     * 지도 zoom 레벨 설정을 한다.
     * @param zoomLevel
     */
    onSetZoomLevel(zoomLevel){
      this.zoomLevel = zoomLevel;
    },
    // 맵 관련 함수 endregion



    // 영상 관련 함수 region
    /**
     * 영상 재생을 위한 함수
     * @param mapDataType 데이터 타입
     * @param mapLineData 라인 데이터
     */
    onPlayRemoveData() {
      if (this.playData.LineFullData != null){
        this.mapInstance.onDeleteMarkerAt(this.playData.markerData.mapId);
        this.mapInstance.onDeleteLineAt(this.playData.LineData.mapId);
        this.mapInstance.onDeleteLineAt(this.playData.LineFullData.mapId);
        this.playData.markerData = null;
        this.playData.LineData = null;
        this.playData.LineFullData = null;
      }
    },
    onPlayCreateData(mapLineData){
      if(this.playData.LineFullData != null){
        this.onPlayRemoveData();
      }

      //전체 라인 데이터 설정
      this.playData.LineFullData = mapLineData;
      this.playData.LineFullData.isShow = true;
      this.playData.LineFullData.locationGroup.sort(function (a, b) {
        return a.index - b.index;
      });

      //현재 라인 데이터 설정
      this.playData.LineData = JSON.parse(JSON.stringify(this.playData.LineFullData));

      //전체 라인 데이터 값 넣기
      this.playData.LineFullData.mapId = MapDataType.ID_PLAYER_LINE_FULL + "_" + mapLineData.id;
      this.playData.LineFullData.line = this.createLinePointDataList(this.playData.LineFullData.locationGroup);
      this.playData.LineFullData.color = this.grayColor;

      //현재 라인 데이터 값 넣기
      this.playData.LineData.mapId = MapDataType.ID_PLAYER_LINE + "_" + mapLineData.id;
      this.playData.LineData.line = this.playData.LineFullData.line.slice(0, 1);
      this.playData.LineData.color = this.redColor;

      //맵 전체 라인 생성
      this.mapInstance.onCreateLine(this.playData.LineFullData);
      //맵 첫번째 라인 생성
      this.mapInstance.onCreateLine(this.playData.LineData);

      console.log(mapLineData.mapDataType)
      //맵 마커 생성
      if(this.playData.LineFullData.line.length > 0) {
        let lineData = this.playData.LineFullData.line[0];
        let imageName = this.getImageName(mapLineData.mapDataType == null ? MapDataType.ID_DRONE : mapLineData.mapDataType, mapLineData.isMain, null);
        this.playData.markerData = new MapMarkerData();
        this.playData.markerData.id = mapLineData.id;
        this.playData.markerData.mapId = MapDataType.ID_PLAYER_MARKER + "_"+ mapLineData.id;
        this.playData.markerData.imageName = imageName;
        this.playData.markerData.lng = lineData.lng;
        this.playData.markerData.lat = lineData.lat;
        this.playData.markerData.mapDataType = MapDataType.ID_PLAYER_MARKER;
        this.playData.markerData.gimbalDirection = lineData.gimbalDirection;
        this.playData.markerData.rotate = lineData.rotate;
        this.playData.markerData.html = this.onGetHtmlContent(this.playData.markerData);
        this.mapInstance.onCreateMarker(this.playData.markerData);
      }
    },

    /**
     * 현재 재생중인 위치 이동
     * @param mapDataType 데이터 타입
     * @param mapLineData 라인 데이터. 시간 밀리세컨드 제거한 값
     */
    onPlayUpdatePosition(time){
      if(this.playData.LineFullData == null){
        return;
      }
      var sec = time;
      if (this.playData.LineFullData.line != null && this.playData.LineFullData.line.length > 0) {
        var line = this.playData.LineFullData.line;
        var first = line[0];
        var isFindIndex = (element) => {
          var firstTime = first.time.getTime() / 1000;
          var filterTime = element.time.getTime() / 1000;
          var findTime = filterTime - firstTime;
          return sec <= findTime;
        };

        var findIndex = line.findIndex(isFindIndex);

        if (findIndex != -1) {
          if (findIndex > line.length) {
            findIndex = line.length;
          }
        } else {
          findIndex = 0;
        }
        this.playData.LineData.line = this.playData.LineFullData.line.slice(0, findIndex);
        this.mapInstance.onSetLineReplace(this.playData.LineData);
        if(this.playData.LineData.line.length > 0) {
          this.playData.markerData.lng = this.playData.LineData.line[this.playData.LineData.line.length - 1].lng;
          this.playData.markerData.lat = this.playData.LineData.line[this.playData.LineData.line.length - 1].lat;
          this.playData.markerData.rotate = this.playData.LineData.line[this.playData.LineData.line.length - 1].rotate;
          this.playData.markerData.gimbalDirection = this.playData.LineData.line[this.playData.LineData.line.length - 1].gimbalDirection;
          this.playData.markerData.html = this.onGetHtmlContent(this.playData.markerData);
          if(this.isFollow) {
            this.onLngLatToMoveCenter(this.playData.markerData.lng, this.playData.markerData.lat) ;
          }
          this.$emit('play-marker-lng-lat',this.playData.markerData.lng, this.playData.markerData.lat, findIndex)
          this.mapInstance.onModifyMarkerAt(this.playData.markerData);
        }
      }
    },

    getPlayDataLineFullDataIndex(id){
      if(this.playData.LineFullData.length)
        return this.playData.LineFullData.findIndex(item => {return item.mapId == id})
      else
        return -1
    },
    // 영상 관련 함수 endregion


    // 맵 라인 함수 region
    /**
     * 라인 생성
     * @param mapDataType 라인 타입
     * @param data api 위치 데이터
     */
    onSetIsPolylineClick(){
      this.mapInstance.onSetIsPolylineClick();
    },
    onCreateLine(mapDataType, mapLineData){
      let imageName = this.getImageName(mapDataType, mapLineData.isMain, null);
      mapLineData.mapId = mapDataType + "_"+ mapLineData.id;
      mapLineData.imageName = imageName;
      mapLineData.isShow = this.isShowLine && this.getIsShowDataType(mapDataType);
      mapLineData.color = mapLineData.isMain == true ? this.redColor : this.grayColor;
      if(mapDataType == MapDataType.ID_WAYPOINT || mapDataType == MapDataType.ID_MAPPING_POLYGON_LINE) {
        mapLineData.color = mapLineData.isMain == true ? this.missionLineMainColor : this.missionLineSubColor;
      }
      if(mapDataType == MapDataType.ID_DRONE && mapLineData.playerIndex != undefined){
        mapLineData.color = this.vodLiveColor[mapLineData.playerIndex];
      }
      mapLineData.line = this.createLinePointDataList(mapLineData.locationGroup);
      this.mapInstance.onCreateLine(mapLineData);
    },

    onCreateLineIndex(mapDataType, mapLineData, index){
      let imageName = this.getImageName(mapDataType, mapLineData.isMain, null);
      mapLineData.mapId = mapDataType + "_"+ mapLineData.id;
      mapLineData.imageName = imageName;
      mapLineData.isShow = this.isShowLine && this.getIsShowDataType(mapDataType);
      mapLineData.color = mapLineData.isMain == true ? this.redColor : this.grayColor;
      if(mapDataType == MapDataType.ID_WAYPOINT || mapDataType == MapDataType.ID_MAPPING_POLYGON_LINE) {
        mapLineData.color = mapLineData.isMain == true ? this.missionLineMainColor : this.missionLineSubColor;
      }
      if(mapDataType == MapDataType.ID_DRONE && mapLineData.playerIndex != undefined){
        mapLineData.color = this.vodLiveColor[mapLineData.playerIndex];
      }
      mapLineData.line = this.createLinePointDataList(mapLineData.locationGroup);
      this.mapInstance.onCreateLineIndex(mapLineData, index);
    },

    createLinePointDataList(data){
      var result = []
      if(data.length > 0) {
        data.forEach(item => {
          var linePointData = new MapLinePointData();
          linePointData.index = item.index;
          linePointData.lng = item.lng;
          linePointData.lat = item.lat;
          linePointData.gimbalDirection = item.gimbalDirection;
          if (item.azimuth != undefined) {
            linePointData.rotate = item.azimuth;
          }
          if (item.time != undefined) {
            linePointData.time = new Date(item.time);
          }
          result.push(linePointData);
        });
      }
      return result;
    },
    /**
     * 라인 제거
     */
    onDeleteLineAt(mapDataType, id){
      let mapId = mapDataType + "_"+ id;
      this.mapInstance.onDeleteLineAt(mapId);
    },
    /**
     * 라인 갯수
     */
    onGetLineSize(mapDataType, id){
      let mapId = mapDataType + "_"+ id;
      return this.mapInstance.onGetLineSize(mapId);
    },
    /**
     * 라인 변경
     */
    onModifyLineAddAt(mapDataType, mapLineData){
      mapLineData.mapId = mapDataType + "_"+ mapLineData.id;
      mapLineData.isShow = this.isShowLine && this.getIsShowDataType(mapDataType);
      mapLineData.line = this.createLinePointDataList(mapLineData.locationGroup);
       if(mapDataType == MapDataType.ID_DRONE && mapLineData.playerIndex != undefined){
        mapLineData.color = this.vodLiveColor[mapLineData.playerIndex];
      }
      this.mapInstance.onSetLineAdd(mapLineData)
    },
    onModifyLineReplaceAt(mapDataType, mapLineData){
      mapLineData.mapId = mapDataType + "_"+ mapLineData.id;
      mapLineData.isShow = this.isShowLine && this.getIsShowDataType(mapDataType);
      mapLineData.line = this.createLinePointDataList(mapLineData.locationGroup);
      this.mapInstance.onSetLineReplace(mapLineData)
    },
    onLineIsSelected(mapDataType, mapLineData, isSelected){
      mapLineData.isMain = isSelected;
      mapLineData.mapId = mapDataType + "_"+ mapLineData.id;
      mapLineData.color = mapLineData.isMain == true ? this.redColor : this.grayColor;
      this.mapInstance.onModifyLineAt(mapLineData);
    },
    onIsLine(mapDataType, id){
      let mapId = mapDataType + "_"+ id;
      return this.mapInstance.onIsLine(mapId);
    },
    // 맵 관련 함수 endregion

    // 맵 라인 region
    onSetIsMarkerMove(){
      this.mapInstance.onSetIsMarkerMove();
    },
    onSetIsMarkerClick(){
      this.mapInstance.onSetIsMarkerClick();
    },
    /**
     * 맵 마커에 선택 또는 해제
     * @param dataType 영상, 사람, 드론 등 타입, 아아디 prefix 이름 설정
     * @param id 데이터 아이디
     * @param isSelected 선택 유무
     */
    onMarkerIsSelected(mapDataType, mapMarkerData, isSelected){
      mapMarkerData.isMain = isSelected;
      var imageName = this.getImageName(mapDataType, mapMarkerData.isMain, mapMarkerData.pointType);
      mapMarkerData.mapId = mapDataType + "_"+ mapMarkerData.id;
      mapMarkerData.imageName = imageName;
      mapMarkerData.isShow = this.getIsShowDataType(mapDataType);
      mapMarkerData.html = this.onGetHtmlContent(mapMarkerData);
      this.mapInstance.onModifyMarkerAt(mapMarkerData);
    },

    onIsMarker(mapDataType, id){
      let mapId = mapDataType + "_"+ id;
      return this.mapInstance.onIsMarker(mapId)
    },
    /**
     * 마커 생성
     * @param mapDataType MapDataType 의 그리는 마커 종류
     * @param mapMarkerData MapMarkerData 형식의 데이터
     */
    onCreateMarker(mapDataType, mapMarkerData){

      var imageName = this.getImageName(mapDataType, mapMarkerData.isMain, mapMarkerData.pointType);
      mapMarkerData.mapId = mapDataType + "_"+ mapMarkerData.id;
      mapMarkerData.isShow = this.getIsShowDataType(mapDataType);
      mapMarkerData.imageName = imageName;
      mapMarkerData.mapDataType = mapDataType;
      mapMarkerData.html = this.onGetHtmlContent(mapMarkerData);

      this.mapInstance.onCreateMarker(mapMarkerData);
    },

    onInfoMarkerShow(mapDataType, markerData, data, event) {
      markerData.mapId = mapDataType + "_"+ markerData.id;
      markerData.mapDataType = mapDataType;
      markerData.html = this.onGetMapInfoHtmlContent(mapDataType, markerData, data, event);
      if(mapDataType == MapDataType.ID_POINT) {
        this.onPointCreateModal(false);
      }
      this.mapInstance.onInfoMarkerShow(mapDataType, markerData);
    },

    onInfoMarkerRemove(mapDataType, id) {
      this.mapInstance.onDeleteMarkerAt(mapDataType +"_" + id);
    },

    /**
     * 마커 생성
     * @param mapDataType MapDataType 의 그리는 마커 종류
     * @param mapMarkerData MapMarkerData 형식의 데이터
     */
    onCreateMarkerIndex(mapDataType, mapMarkerData, index){
      if(index != undefined) {
        var imageName = this.getImageName(mapDataType, mapMarkerData.isMain, mapMarkerData.pointType);
        mapMarkerData.mapId = mapDataType + "_"+ mapMarkerData.id;
        mapMarkerData.isShow = this.getIsShowDataType(mapDataType);
        mapMarkerData.imageName = imageName;
        mapMarkerData.mapDataType = mapDataType;
        mapMarkerData.html = this.onGetHtmlContent(mapMarkerData);
        this.mapInstance.onCreateMarkerIndex(mapMarkerData, index);
      }
    },
    /**
     * 마커 수정
     * @param mapDataType MapDataType 의 그리는 마커 종류
     * @param mapMarkerData MapMarkerData 형식의 데이터
     */
    onModifyMarkerAt(mapDataType, mapMarkerData) {
      var imageName = this.getImageName(mapDataType, mapMarkerData.isMain, mapMarkerData.pointType);
      mapMarkerData.mapId = mapDataType + "_"+ mapMarkerData.id;
      mapMarkerData.imageName = imageName;
      mapMarkerData.isShow = this.getIsShowDataType(mapDataType);
      mapMarkerData.mapDataType = mapDataType;
      mapMarkerData.html = this.onGetHtmlContent(mapMarkerData);
      this.mapInstance.onModifyMarkerAt(mapMarkerData);
    },

    //이미지 데이터 구하기
    getImageName(mapDataType, isMain, pointType){
      var result = null;
      switch (mapDataType){
        case MapDataType.ID_POINT:
          switch (pointType) {
            case "Home":
              result = MapImageName.ID_ICON_POINT_HOME;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_HOME_SELECTED;
              // }
              break;
            case "Caution":
              result = MapImageName.ID_ICON_POINT_CAUTION;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_CAUTION_SELECTED;
              // }
              break;
            case "Danger":
              result = MapImageName.ID_ICON_POINT_DANGER;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_DANGER_SELECTED;
              // }
              break;
            case "Search":
              result = MapImageName.ID_ICON_POINT_SEARCH;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_SEARCH_SELECTED;
              // }
              break;
            case "Target":
              result = MapImageName.ID_ICON_POINT_TARGET;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_TARGET_SELECTED;
              // }
              break;
            case "Station":
              result = MapImageName.ID_ICON_POINT_STATION;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_STATION_SELECTED;
              // }
              break;
          }
          break;
        case MapDataType.ID_DRONE:
          result = MapImageName.ID_ICON_OTHER_DRONE;
          if(isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_DRONE;
          }
          if(pointType != undefined ) {
            switch (pointType){
              case "red":
                  result = MapImageName.ID_ICON_DRONE_RED
                break;
              case "blue" :
                  result = MapImageName.ID_ICON_DRONE_BLUE
                break;
              case "orange" :
                  result = MapImageName.ID_ICON_DRONE_ORANGE
                break;
              case "purple":
                  result = MapImageName.ID_ICON_DRONE_PURPLE
                break;
            }
          }
          break;
        case MapDataType.ID_USER:
          result = MapImageName.ID_ICON_OTHER_USER;
          if(isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_USER;
          }
          break;
        case MapDataType.ID_STATION:
          result = MapImageName.ID_ICON_STATION;
          break;
        case MapDataType.ID_CONTROLLER:
          result = MapImageName.ID_ICON_OTHER_CONTROLLER;
          if(isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_CONTROLLER;
          }
          break;
        case MapDataType.ID_STREAMER :
        result = MapImageName.ID_ICON_STREAMER;
          if(isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_OTHER_STREAMER;
          }
          break;
        case MapDataType.ID_WAYPOINT:
          result = MapImageName.ID_ICON_WAYPOINT;
          if(isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_WAYPOINT_SELECTED;
          }
          break;
        case MapDataType.ID_SITE:
          result = MapImageName.ID_ICON_SITE;
          if(isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_SITE_SELECTED;
          }
          break;
        case MapDataType.ID_SITE_CIRCLE:
          result = MapImageName.ID_ICON_SITE_CIRCLE;
          if(isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_SITE_SELECTED;
          }
          break;
        case MapDataType.ID_MAPPING_MARKER :
          result = MapImageName.ID_ICON_POINT_MAPPING;
          break;
        case MapDataType.ID_SCHEDULE_STATION:
          result = MapImageName.ID_SCHEDULE_STATION_GREY
          if(isMain != undefined && isMain == true) {
            result = MapImageName.ID_SCHEDULE_STATION_GREEN
          }
      }
      return result;
    },
    /**
     * 해당 마커 제거
     * @param mapDataType MapDataType 의 그리는 마커 종류
     * @param id 해당 아이디
     */
    onDeleteMarkerAt(mapDataType, id) {
      this.mapInstance.onDeleteMarkerAt(mapDataType +"_" + id);
    },

    onDeleteMarkerIndex(mapDataType, index){
      this.mapInstance.onDeleteMarkerIndex(mapDataType, index);
    },

    onLeaveIds(mapDataType, ids) {
      if(ids.length == 0) {

        var deleteMarkerData = new MapMarkerData();
        deleteMarkerData.mapId = mapDataType;
        this.mapInstance.onDeleteMarkerList(deleteMarkerData);

        var deleteLineData = new MapLineData();
        deleteLineData.mapId = mapDataType;
        this.mapInstance.onDeleteLineList(deleteLineData);
        return;
      }

      var mapIds = []
      ids.forEach(id => {
        mapIds.push(mapDataType +"_" + id)
      })
      this.mapInstance.onLeaveIds(mapDataType, mapIds);


    },
    //# 맵 마커 endregion

     //#region mapping 관련 함수
        onCreateMappingCircle(mapPolygonData){
         mapPolygonData.polygonType= MapDataType.ID_MAPPING_CIRCLE
        //circlepolygon 생성 후 zIndex설정
        this.mapInstance.onCreateCirclePolygon(mapPolygonData)
        this.mapInstance.setZIndexCirclePolygon(mapPolygonData.polygonType,400)
      },

      /**
       * ID 기준이 아닌 mapDataType 기준임
       * 현재는 폴리곤이 유일하게 1개만 사용하는데 추후에 여러개 사용하면서 mapDataType이 같으면 수정해야함.
       */
      onCreateMappingPolygon(mapPolygonData , mapDataType){
        mapPolygonData.polygonType = mapDataType
        //polygon 생성 후 zIndex설정
        if(this.onIsMappingPolygon(mapDataType)){
          this.onUpdateMappingPolygon(mapPolygonData,mapDataType)
        }else{
          this.mapInstance.onCreatePolygon(mapPolygonData);
          this.mapInstance.setZIndexPolygon(mapPolygonData.polygonType,0);
        }
      },
      onUpdateMappingPolygon(mapPolygonData , mapDataType){
        mapPolygonData.polygonType = mapDataType
        if(this.onIsMappingPolygon(mapDataType)){
          this.mapInstance.onUpdatePolygon(mapPolygonData);
          this.mapInstance.setZIndexPolygon(mapPolygonData.polygonType,0);
        }else{
          this.onCreateMappingPolygon(mapPolygonData , mapDataType)
        }
      },
      onIsMappingPolygon(mapDataType){
        return this.mapInstance.onIsPolygon(mapDataType)
      },

      onCreateMappingMarker(mapPolygonData){
        //polygon 생성 후 zIndex설정
        mapPolygonData.posList.forEach((item, index)=>{
          mapPolygonData.isShow = true
          let imageName = this.getImageName(mapPolygonData.mapDataType, mapPolygonData.isShow, null);
          mapPolygonData.imageName = imageName
          mapPolygonData.html = this.onGetHtmlContent(mapPolygonData);
          mapPolygonData.lat = item.lat
          mapPolygonData.lng = item.lng
          mapPolygonData.mapId = index;
          this.mapInstance.onCreateMapMarkerFromPolygon(mapPolygonData);
          this.mapInstance.setZIndexMarkerFromPolygon(MapDataType.ID_MAPPING_MARKER ,200);
        })

      },
      onCreateMappingPolyLine(mapPolygonData, mapDataType){
         mapPolygonData.polygonType = mapDataType
         this.mapInstance.onCreatePolyLine(mapPolygonData);
         this.mapInstance.setZIndexPolyLine(MapDataType.ID_MAPPING_LINE ,300);

      },
      onInitMapping(type){
        this.mapInstance.setMappingInit(type)
      },

      sendAngle(value){
        this.mapInstance.setMappingAngle(value);
      },
      sendInterval(value){
        this.mapInstance.setMappingInterval(value);
      },
      sendCameraType(value){
        this.mapInstance.setMappingCamera(value);
      },
      sendAltitude(value){
        this.mapInstance.setMappingDroneHeight(value);
      },
      sendMappingData(value,type){
        this.mapInstance.setMappingData(value,type);
      },
      onCreateMappingLine(mappingType, polygonType){
        if(mappingType == 'Orthophoto'){//정사영상
          this.mapInstance.onCreateMappingLine(mappingType,polygonType);
        }
      },
    //#endregion mapping 관련 함수

    //live모달
    openLiveModal() {
      this.isLiveModalOpen = true; // 모달 열기
    },
    closeLiveModal() {
      this.isLiveModalOpen = false; // 모달 닫기
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.line-show {
  width: 134px;
  height: 36px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  display: flex;
  left: 652px;
}

.line-show-select {
  width: 50%;
  height: calc(100% - 10px);
  margin: 5px;
  border-radius: 4px;
  text-align: center;
  padding-top: 8px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}

.line-show-select:hover {
  border-color: #505050;
}

.line-show-select.map-active {
  color: #ffffff;
  background-color: #0080FF;
}

.line-show-select.map-active:hover {
  border-color: #505050;
}

.map-type-change {
  position: absolute;
  width: 328px;
  height: 140px;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -164px;
  padding: 11px 12px 19px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  z-index: 10;
}

.map-type-change > span {
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4rem;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.map-type-change > button {
  position: absolute;
  padding: 0px;
  width: 24px;
  height: 24px;
  right: 12px;
  top: 12px;
  border: 0px;
}

.map-type-change > button > image {
  display: contents;
}

.map-type-change > img {
  position: absolute;
  width: 80px;
  height: 45px;
  top: 51px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px #aaaaaa;
}

.map-type-change > img.satellite {
  left: 21px;
  background-image: url(../../assets/images/map/map-type-change-satellite.png);
}
.map-type-change > img.terrain {
  left: 124px;
  background-image: url(../../assets/images/map/map-type-change-terrain.png);
}
.map-type-change > img.normal {
  left: 228px;
  background-image: url(../../assets/images/map/map-type-change-normal.png);
}
.map-type-change > img.map-type-imag-active {
  border: solid 1px #00c4ff;
}


.map-point-div {
  position: absolute;
  top: calc(50% + 100px); /* 25px = 103px :map-point-create height/2 - 78 : image */
  left: 50%;
  transform: translate(-50%, -50%);
}
.map-point-div.google {
  top: calc(50% - 50px); /* 25px = 103px :map-point-create height/2 - 78 : image */
}
.map-point-modify-div {
  position: absolute;
  top: 67%; /* 25px = 103px :map-point-create height/2 - 78 : image */
  left: 50%;
  transform: translate(-50%, -40%);
}

.map-point-modify-div.google {
  top: calc(50% + 5px); /* 25px = 103px :map-point-create height/2 - 78 : image */
}

.map-point-div > div.select-image {
  text-align: center;
  padding-bottom: 10px;
}
.map-point-div > div > img.create {
  text-align: center;
  height: 51px;
}

.map-point-create {
  width: 430px;
  height: 220px;
  background: white;
  padding: 20px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
}

.lnglatData>span{
  display: inline-block;
  line-height: 1.5rem;
  font-weight: bold;
  margin-right: 20px;
}
.lnglatData>.locationInfo{
  width: calc(85% - 10px);
}

.map-point-create > div.map-point-title > span{
  font-weight: bolder;
  font-size: 1.6rem;
  font-family: NotoSansCJKkr;
  margin-top: 5px;
  margin-bottom: 15px;
}

.select-group{
  width: 100%;
}
.select-group > span{
  font-weight: bold;
  font-size: 1rem;
  margin: 5px 0px;
}

/*.map-point-create > div.select-group {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
}*/
.map-point-create > div.select-group > span {
  margin-right: 20px;
}
.map-point-create > div.select-group > div.selectCont {
  width: calc(85% - 10px);
}
.map-point-create > div.select-group > div.selectCont > div.select {
  display: inline-block;
  background-color: white;
  width: max-content;
  height: 30%;
  text-align: left;
  vertical-align: center;
  line-height: 2.6rem;
  cursor: pointer;
  margin-right: 25px;
}
.map-point-create > div.select-group > div.selectCont > div.select:nth-last-child{
  margin-right: 0;
  }
.map-point-create > div.select-group > div.selectCont > div.select > span{
  font-size: 1.2rem;
 }

.map-point-create > div.select-group > div.selectCont > div.active {
  color: #0080ff;
  font-weight: bold;
}

.map-point-create > div > span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 1.2rem;
}

.map-point-create > div.select-image {
  text-align: center;
  padding-top: 20px;
}

.map-point-create > div > img.create {
  text-align: center;
  height: 51px;
}

.map-point-create > div.insert-data > span {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 2rem;
}

.map-point-create > div.insert-data > textarea {
  width: calc(80% - 10px);
  height: 53px;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 1.2rem;
  background-color: white;
  resize: none;
}

.map-point-create > div.insert-data > .btn-wrap{
  text-align: center;
  margin: 10px 0px;
}
button.medium{
  padding: 0px 30px;
}
button.medium.point.margin6{
  margin-right: 5px;
}
.map-point-create > div.map-point-data > span{
  font-weight: bold;
  font-size: 1.2rem;
  margin: 5px 0px;
}

.map-point-create > div.map-point-data > div.map-point-content>span{
  font-size: 1.2rem;
  line-height: 1.5rem;
}
.map-point-create > div.map-point-data > div.map-point-content{
  display: inline-block;
  vertical-align: center;
  margin-left: 20px;
}

.mapTop{
  height: 36px;
  position: absolute;
  z-index: 1;
  padding: 9px 11px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}
.latLngName{
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0b7dd9;
  display:inline;
}
.latValue{
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-left: 5px;
  width: calc(45% - 21px);
  display: inline;;
}
.lngValue{
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-left: 5px;
  width: calc(45% - 21px);
  display: inline;;
}

.locationInfo{
  display: flex;
  justify-content: space-between;
}


.locationInfo div{
  font-size: 1.2rem;
  font-weight: normal;
}
.infoAlert{
  width: 300px !important;
  height: 180px;
  padding: 10px 15px;
  z-index: 1000;
  position: absolute;
  bottom: 40px;
  border-radius: 5px;
  left: 20px;
  margin: 0;
  background: rgba(16, 16, 16, 0.82);
  border: 0;
}
.logInfo-list::-webkit-scrollbar-thumb {
    background-color: #6e6f72;
    border-radius: 4px;
}
.logInfo-list::-webkit-scrollbar-track {
    border-radius: 2px ;
    background: rgba(40, 43, 53, 0.0);
    border: 0px solid #f0f0f0;
}
.InfoDt {
    position: relative;
    color: #ffffff;
    font-weight: normal;
    padding: 10px 0px 10px 10px;
    border-bottom: 1px solid #707070;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.InfoDt > div > span{
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.InfoDt > div > img{
  width: 12px;
  margin-right: 10px;
}
.InfoDt > span:nth-child(3){
  position: absolute;
  right: 15px;
}
/*지도 위 누적 카운트*/
.infoCount{
  width: 100% !important;
  height: auto;
  margin: 0;
  padding: 0px 15px;
  padding-bottom: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #4f4f4f;
  background: rgb(35 35 35);
}
.infoCount > h2{
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 15px;
  padding-top: 10px;
}
.allinfoCount{
  width: max-content !important;
  z-index: 1000;
  margin: 0;
  background: #101010;
  padding: 3px 10px;
  position: absolute;
  top: -5px;
  border-radius: 4px;
  left: 50%;
  transform: translate(-50%, 20px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.cntIn{
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  margin: 5px 10px;
}
.cntIn:nth-last-child{
  margin-bottom: 0px;
}
.cntIn>b{
  font-size: 1.2rem;
  line-height: 3rem;
  color: #cbcbcb;
  font-weight: 400;
  margin-left: 5px;
}
.cntIn>span{
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: 400;
  line-height: 3rem;
  margin-left: 10px;
}
.cntIn> .cntIn-img{
  width: 30px;
  text-align: center;
}
.cntIn > img{margin: 0 5px;}
img.gimbal-direction-title {
  top: 30px;
  left: 30px;
  position: absolute;
  display: -webkit-box;
  width: 60px;
  height: 60px;
  max-height: 60px;
}

img.gimbal-direction {
  top: -15px;
  left: -15px;
  position: absolute;
  display: -webkit-box;
  width: 60px;
  height: 60px;
  max-height: 60px;
}

#info-root > div.circle{
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  text-align: center;
  vertical-align: middle;
  line-height: 25px;
  color: white;
}

#info-root > div.showName {
  width: 30px;
  height: 30px;
  transform: translate(0px, 3px);
  text-shadow: 0 0 2px #000;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

#info-root > div.waypoint {
  position: absolute;
  color: #ffffff;
  z-index: 1;
  top: 0px;
  left: 5px;
}
.video-player .video-js{width: 100%;height: 100%;padding-top: 56.25%;}

@media (max-width: 1600px){
  .modal{transform: translate(-50%, -55%);}
  .weatherWrap > .cover > img{margin: 10px 0 10px !important;height: 30px !important;}
  .weatherWrap > .cover > h2{font-size: 1.4rem !important;}
}

</style>
