<template>
  <div v-if="isUserCenterShowButton" class="map-btn4" style="z-index:2; position: absolute" :style="[{ 'top':  getMapUserCenterTop + lngLatShowUnder + positionAndLienUnder + 'px' }, isLeftPosition == true? {'left' :defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'}]">
    <div class="small" style="display: contents" @click="moveCenter">
      <img v-if="isUserCenterSelected" src="@/assets/images/map/ic_map_user_center_selected.svg" class="button-icon"/>
      <img v-if="!isUserCenterSelected" src="@/assets/images/map/ic_map_user_center.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import moment from "moment";
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";

export default {
  name: "MapUserCenter",
  components: {  },
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      moment: moment,
      //버튼 전체 값 region
      isUserCenterSelected : false,
    };
  },
  mounted() {
  },
  created() {
  },
  computed : {
    ...mapState({
      isUserCenterShowButton: state => state.map.isUserCenterShowButton,
    }),
    getMapUserCenterTop(){
      return this.$store.getters["map/getMapUserCenterTop"];
    },
  },
  watch: {
  },
  methods: {
    moveCenter() {
      this.$emit("map-center",this.isUserCenterSelected);
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
